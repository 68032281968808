// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { createContext, useContext, useState } from 'react';
import type { PropsWithChildren } from 'react';

export interface ReportsContextType {
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
}

export const ReportsContext = createContext<Partial<ReportsContextType>>({
  link: '',
});

export const useReportsContext = (): Partial<ReportsContextType> => {
  const context = useContext(ReportsContext);

  return context;
};

const ReportsContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [link, setLink] = useState('');
  return (
    <ReportsContext.Provider value={{ link, setLink }}>
      {children}
    </ReportsContext.Provider>
  );
};

export default ReportsContextProvider;
