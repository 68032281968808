// Copyright 2022, Imprivata, Inc.  All rights reserved.

export enum ErrorMessages {
  'INCORRECT_TENANT_ID' = 'Invalid Tenant ID. Check the Tenant ID in the URL and try again.',
  'TENANT_ID_NO_PROVIDED' = 'No Tenant ID provided.',
}

export enum ErrorCodes {
  'SESSION_EXPIRED' = 'session-expired',
  'IDP_DISABLED' = 'idp-disabled',
  'CREDENTIALS_EXPIRED' = 'credential-expired',
  'AMBIGUOUS_USERNAME' = 'ambiguous-username',
  'NO_PERMISSION' = 'UXID_ADMIN_NOTALLOWED',
}

// query parameter keys
export const QueryParamKey = {
  TENANT_ID: 'tenantId',
};

export enum StorageKeys {
  SESSION_ID = 'SESSION_ID',
  HAS_VALID_SESSION = 'HAS_VALID_SESSION',
  TENANT_ID = 'TENANT_ID',
  TENANT_TYPE = 'TENANT_TYPE',
  CODING_CONTEXT = 'CODING_CONTEXT',
}
