// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { dashboardEpic } from '../containers/dashboard/store/epics';
import { fhirConfigurationEpic } from '../containers/integrations/containers/fhir-configuration/store/epics';
import { loginEpics } from '../containers/login/store/epics';
import { patientSearchEpics } from '../containers/patient-search/store/epics';
import { patientDetailsEpic } from '../containers/patient-details/store/epics';
import { reportEpics } from '../containers/reports/store/epics';
import { hl7ConfigurationEpic } from '../containers/integrations/containers/hl7-configuration/store/epics';
import { createErrorBannerEpic } from './error-banner-state/epics';

const rootEpic = combineEpics(
  dashboardEpic,
  fhirConfigurationEpic,
  hl7ConfigurationEpic,
  loginEpics,
  patientSearchEpics,
  patientDetailsEpic,
  reportEpics,
  createErrorBannerEpic,
);

export type RootEpic = ReturnType<typeof rootEpic>;

export default rootEpic;
