// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useSelector } from 'react-redux';
import {
  isPatientSearchingSelector,
  patientSearchErrorSelector,
  patientSearchResponseSelector,
  patientSelectedSelector,
} from './selectors';

export const usePatientSearch = (): {
  searchResponse: ReturnType<typeof patientSearchResponseSelector>;
  isSearching: ReturnType<typeof isPatientSearchingSelector>;
  error: ReturnType<typeof patientSearchErrorSelector>;
  selectedPatient: ReturnType<typeof patientSelectedSelector>;
} => {
  const searchResponse = useSelector(patientSearchResponseSelector);
  const isSearching = useSelector(isPatientSearchingSelector);
  const error = useSelector(patientSearchErrorSelector);
  const selectedPatient = useSelector(patientSelectedSelector);
  return {
    searchResponse,
    isSearching,
    error,
    selectedPatient,
  };
};
