// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Statistic } from 'antd';
import clsx from 'clsx';
import type { CardProps } from '../../../../components/card/Card';
import classes from './DashboardCard.module.less';
import Card from '../../../../components/card/Card';

const DashboardCard: React.FC<
  {
    data: string;
    label: string;
  } & CardProps
> = ({ data, label, ...rest }) => {
  return (
    <Card {...rest} className={clsx('elevation-off', rest.className || '')}>
      <Statistic
        title={label}
        value={data}
        className={classes.stat}
        groupSeparator=""
      />
    </Card>
  );
};

export default DashboardCard;
