// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map, switchMap, catchError, filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import type { RootAction } from '../../../store/rootAction';
import { ContextNames } from '../../../i18n';
import { reportActions } from './actions';
import { runReport$ } from '../../../api/services/reportsService';
import { getErrorMessageCode } from '../../../i18n/utils';
import { endRunReportSpan, startRunReportSpan } from '../tracing';
import { showErrorBannerAction } from '../../../store/error-banner-state/actions';
import { errors } from '../../../store/error-banner-state/errors';

export const runReportEpic: Epic<RootAction> = action$ =>
  action$.pipe(
    filter(isActionOf(reportActions.request)),
    tap(() => {
      startRunReportSpan();
    }),
    switchMap(({ payload }) => {
      return runReport$(
        payload.name,
        payload.startDate,
        payload.endDate,
        payload.timeZoneId,
      ).pipe(
        tap(response => {
          endRunReportSpan();
          const downloadUrl = URL.createObjectURL(response.data);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = response.fileName || 'Report.csv';
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(downloadUrl);
          link.remove();
        }),
        map(response => reportActions.success(response)),
        catchError(error => {
          endRunReportSpan(error);
          return of(
            reportActions.failure({
              code: getErrorMessageCode(
                ContextNames.REPORTS,
                'run-report-failed',
              ),
              message:
                'An error occurred while processing the report. Please try again.',
            }),
            showErrorBannerAction.request(errors.REPORTS_MESSAGE_RUN_FAILED()),
          );
        }),
      );
    }),
  );

export const reportEpics = combineEpics(runReportEpic);
