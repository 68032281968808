// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Layout, Spin } from 'antd';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { LOGIN_ROUTE } from '../../routers/route-names';
import layoutClasses from '../../styles/layout.module.less';
import { loginSelector } from '../login/store/selectors';
import { logoutAction } from '../login/store/actions';

export const Logout: React.FC = function Logout() {
  const dispatch = useDispatch();
  const { tenantId, hasValidSession } = useSelector(loginSelector);

  const isLogoutFinished = !hasValidSession;
  const isTenantIdStored = typeof tenantId === 'string';

  React.useEffect(() => {
    dispatch(logoutAction.request());
  }, [dispatch]);

  return (
    <Layout
      data-testid="logout"
      className={clsx(
        layoutClasses.mainContainer,
        layoutClasses.centeredContent,
      )}
    >
      <Spin />
      {isTenantIdStored && isLogoutFinished && (
        <Redirect to={`${LOGIN_ROUTE}?tenantId=${tenantId}`} />
      )}
    </Layout>
  );
};

export default Logout;
