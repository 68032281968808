// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type { FhirConfiguration } from '@imprivata-cloud/adminapi-client';
import type { RootAction } from '../../../../../store/rootAction';
import {
  fhirConfigurationGetActions,
  fhirConfigurationSaveActions,
} from './actions';
import { invalidSessionAction } from '../../../../login/store/actions';

export interface FhirConfigurationState {
  loading: boolean;
  error: string | null;
  configuration: FhirConfiguration | null;
}

export const initialState: FhirConfigurationState = {
  loading: false,
  error: null,
  configuration: null,
};

export const fhirConfigurationReducer = combineReducers<FhirConfigurationState>(
  {
    loading: createReducer<boolean>(initialState.loading)
      .handleAction(
        [
          fhirConfigurationGetActions.request,
          fhirConfigurationSaveActions.request,
        ],
        () => true,
      )
      .handleAction(
        [
          fhirConfigurationGetActions.cancel,
          fhirConfigurationGetActions.success,
          fhirConfigurationGetActions.failure,
          fhirConfigurationSaveActions.cancel,
          fhirConfigurationSaveActions.success,
          fhirConfigurationSaveActions.failure,
          invalidSessionAction.request,
        ],
        () => false,
      ),
    configuration: createReducer<FhirConfiguration | null, RootAction>(
      initialState.configuration,
    )
      .handleAction(
        [
          fhirConfigurationGetActions.success,
          fhirConfigurationSaveActions.request,
        ],
        (_, { payload }) => {
          return {
            audience: payload?.audience,
            clientId: payload?.clientId,
            issuer: payload?.issuer,
            identifierSystem: payload?.identifierSystem,
            type: payload?.type,
            modifiedTimestamp: (payload as FhirConfiguration)
              ?.modifiedTimestamp,
            modifiedBy: (payload as FhirConfiguration)?.modifiedBy,
            epicRestApiBaseAddress: payload?.epicRestApiBaseAddress,
          };
        },
      )
      .handleAction([invalidSessionAction.request], () => null),
    error: createReducer<string | null, RootAction>(initialState.error)
      .handleAction(
        [
          fhirConfigurationGetActions.request,
          fhirConfigurationGetActions.success,
          fhirConfigurationSaveActions.request,
          fhirConfigurationSaveActions.success,
          invalidSessionAction.request,
        ],
        () => null,
      )
      .handleAction(
        [
          fhirConfigurationGetActions.failure,
          fhirConfigurationSaveActions.failure,
        ],
        (_, { payload }) => payload.code || null,
      )
      .handleAction(
        [
          fhirConfigurationGetActions.cancel,
          fhirConfigurationSaveActions.cancel,
        ],
        () => 'cancelled',
      ),
  },
);
