// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import { useTranslation } from 'react-i18next';
import { QuestionCircleOutlined } from '@ant-design/icons';
import type { ActionButtonProps } from './types';

const HelpButton: React.FC<ActionButtonProps> = function HelpButton({
  onClick,
  className,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Button
      label={t('actions.help')}
      data-testid="help-button"
      variant={ButtonVariant.TEXT}
      icon={<QuestionCircleOutlined />}
      className={className}
      onClick={onClick}
      disabled={disabled}
      hidden={true}
    />
  );
};

export default HelpButton;
