// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React from 'react';
import clsx from 'clsx';
import { Col, Layout, Row } from 'antd';
import { useSelector } from 'react-redux';
import { TenantType } from '@imprivata-cloud/adminapi-client';
import { useTranslation } from 'react-i18next';
import ImprivataLogo from '../../assets/icons/imprivata.svg?react';

import classes from './Header.module.less';
import { tenantTypeSelector } from '../../containers/login/store/selectors';

export interface HeaderProps {
  title: React.ReactNode;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({ title, className, ...rest }) => {
  const { t } = useTranslation();

  const tenantType = useSelector(tenantTypeSelector);

  return (
    <Layout.Header className={clsx(classes.header, className)} {...rest}>
      <Layout className={clsx(classes.section, className)}>
        <Row
          justify="space-between"
          style={{ margin: 'auto 0 auto 0' }}
          align={'middle'}
        >
          <Col>
            <h2 className="h2-header" style={{ marginBottom: 0 }}>
              {title}
            </h2>
          </Col>
          {tenantType === TenantType.Test && (
            <Col
              style={{
                marginLeft: 'auto',
                marginRight: '1.5em',
                lineHeight: '16px',
              }}
            >
              <div data-testid="header-chip" className={classes.chip}>
                {t('header.test-tenant-chip')}
              </div>
            </Col>
          )}
          <Col flex="none" style={{ display: 'flex', justifyContent: 'end' }}>
            <ImprivataLogo data-testid="imprivataLogo" width={'147px'} />
          </Col>
        </Row>
      </Layout>
    </Layout.Header>
  );
};

export default Header;
