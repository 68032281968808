// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LoginContainer from './LoginContainer';
import { loginRoutes } from '../../routers/route-names';

import styles from './LoginLayout.module.less';

const LoginRoutes: React.FC = () => {
  return (
    <div className={styles.mainContainer} data-testid="login-layout">
      <Switch>
        <Route path={loginRoutes.BASE}>
          <LoginContainer />
        </Route>
      </Switch>
    </div>
  );
};

export default LoginRoutes;
