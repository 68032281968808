// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { map } from 'rxjs/operators';
import type {
  FhirConfiguration,
  GetFhirConfigurationResponse,
  SaveFhirConfigurationRequest,
} from '@imprivata-cloud/adminapi-client';
import type { Observable } from 'rxjs';
import { promiseToObservable, adminApiClient } from '../client';

export function saveFhirConfiguration$(
  request: SaveFhirConfigurationRequest,
): Observable<void> {
  return promiseToObservable(adminApiClient.saveFhirConfiguration(request));
}

export function getFhirConfiguration$(): Observable<FhirConfiguration | null> {
  return promiseToObservable(adminApiClient.getFhirConfiguration()).pipe(
    map(
      (response: GetFhirConfigurationResponse) =>
        response.configuration ?? null,
    ),
  );
}
