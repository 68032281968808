// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError } from 'axios';
import { createContextPropagationInterceptor } from '@imprivata-cloud/common';
import type { InternalAxiosRequestConfig } from 'axios';
import type { AppDispatch } from '../store/createStore';
import { client } from './client';
import { Headers, invalidSessionError } from './constants';
import { invalidSessionAction } from '../containers/login/store/actions';
import { LOGIN_ROUTE } from '../routers/route-names';
import { QueryParamKey } from '../containers/login/constants';
import { tracer } from '../tracing';

const sessionStartRoute = 'session/start';
const logoutRoute = 'session/logout';

export const contextPropagationInterceptor = async (
  config: InternalAxiosRequestConfig<any>,
): Promise<InternalAxiosRequestConfig<any>> => {
  const route = config.url ?? '';
  const tenantId = new URL(window.location.href).searchParams.get(
    QueryParamKey.TENANT_ID,
  );
  const newHeaders: any = {};

  config.withCredentials = isAnyRoute(route, [sessionStartRoute, logoutRoute])
    ? true
    : config.withCredentials;

  if (tenantId) {
    newHeaders[Headers.ImprTenantId] = tenantId;
  }

  config.headers = {
    ...config.headers,
    ...newHeaders,
  };

  return config;
};

function dispatchInvalidSessionLogout(dispatch: AppDispatch) {
  const location = window.location;
  dispatch(
    invalidSessionAction.request({
      errorMsgKey: 'login.logout-due-invalid-session',
      storedURL:
        location.pathname !== LOGIN_ROUTE
          ? location.pathname + location.search
          : undefined,
    }),
  );
}

export const invalidSessionInterceptor = (dispatch: AppDispatch) => {
  return (res: AxiosError): AxiosError => {
    if (
      res.response?.status === 401 &&
      !isAnyRoute(res.config?.url ?? '', [logoutRoute])
    ) {
      tracer.endAllSpans();
      tracer.forceFlush();
      dispatchInvalidSessionLogout(dispatch);
      throw new AxiosError(invalidSessionError);
    }
    throw res;
  };
};

let requestInterceptors: number[] = [];
let responseInterceptors: number[] = [];

export const applyInterceptors = (dispatch: AppDispatch): void => {
  const tracingContextPropagationInterceptor =
    createContextPropagationInterceptor(tracer);
  requestInterceptors.push(
    client.interceptors.request.use(tracingContextPropagationInterceptor),
  );

  requestInterceptors.push(
    client.interceptors.request.use(contextPropagationInterceptor),
  );

  responseInterceptors.push(
    client.interceptors.response.use(
      undefined,
      invalidSessionInterceptor(dispatch),
    ),
  );
};

export const ejectInterceptors = (): void => {
  requestInterceptors.forEach(interceptor => {
    client.interceptors.request.eject(interceptor);
  });

  responseInterceptors.forEach(interceptor => {
    client.interceptors.response.eject(interceptor);
  });

  requestInterceptors = [];
  responseInterceptors = [];
};

function isAnyRoute(routeToCheck: string, routes: string[]): boolean {
  for (const route of routes) {
    if (routeToCheck.toLowerCase().endsWith(route)) {
      return true;
    }
  }

  return false;
}
