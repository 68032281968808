// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { useEffect } from 'react';

export default function SetTitle(title: string, plainTitle = false): void {
  useEffect(() => {
    if (title === '') {
      document.title = 'Imprivata Patient Access Administrator Console';
      document
        .getElementsByTagName('meta')
        ?.namedItem('description')
        ?.setAttribute(
          'content',
          'Imprivata Patient Access Administrator Console',
        );
    } else {
      document.title = plainTitle
        ? `${title}`
        : `${title} - Imprivata Patient Access Administrator Console`;
      document
        .getElementsByTagName('meta')
        ?.namedItem('description')
        ?.setAttribute('content', document.title);
    }
  });
}
