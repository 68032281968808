// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type { RootAction } from '../../../store/rootAction';
import type { PatientDecrypted } from '../../../api/types';
import type { PatientSearchRequestDecrypted } from '../types';
import { PatientSearchResponseDecrypted } from '../../../api/types';
import {
  clearPatientSearchAction,
  patientSearchActions,
  selectPatientAction,
} from './actions';
import { patientDeleteActions } from '../../patient-details/store/actions';
import { invalidSessionAction, logoutAction } from '../../login/store/actions';

export interface PatientSearchState {
  searching: boolean;
  error: string | null;
  searchRequest: PatientSearchRequestDecrypted | null;
  searchResponse: PatientSearchResponseDecrypted | null;
  selectedPatient: PatientDecrypted | null;
}

export const initialState: PatientSearchState = {
  searching: false,
  error: null,
  searchRequest: null,
  searchResponse: null,
  selectedPatient: null,
};

export const patientSearchReducer = combineReducers<PatientSearchState>({
  searching: createReducer<boolean>(initialState.searching)
    .handleAction([patientSearchActions.request], () => true)
    .handleAction(
      [
        patientSearchActions.cancel,
        patientSearchActions.success,
        patientSearchActions.failure,
        logoutAction.request,
        invalidSessionAction.request,
      ],
      () => false,
    ),
  searchRequest: createReducer<
    PatientSearchRequestDecrypted | null,
    RootAction
  >(initialState.searchRequest)
    .handleAction([patientSearchActions.request], (_, { payload }) => payload)
    .handleAction(
      [logoutAction.request, invalidSessionAction.request],
      () => initialState.searchRequest,
    ),
  searchResponse: createReducer<
    PatientSearchResponseDecrypted | null,
    RootAction
  >(initialState.searchResponse)
    .handleAction([patientSearchActions.success], (_, { payload }) => payload)
    .handleAction(
      [patientDeleteActions.success],
      (searchResponse, { payload }) =>
        searchResponse?.patients
          ? new PatientSearchResponseDecrypted({
              patients: searchResponse?.patients?.filter(p => p.id !== payload),
            })
          : null,
    )
    .handleAction(
      [
        logoutAction.request,
        invalidSessionAction.request,
        clearPatientSearchAction,
      ],
      () => initialState.searchResponse,
    ),
  error: createReducer<string | null, RootAction>(initialState.error)
    .handleAction(
      [
        patientSearchActions.request,
        patientSearchActions.success,
        logoutAction.request,
        invalidSessionAction.request,
      ],
      () => null,
    )
    .handleAction(
      [patientSearchActions.failure],
      (_, { payload }) => payload.code || null,
    )
    .handleAction([patientSearchActions.cancel], () => 'cancelled'),
  selectedPatient: createReducer<PatientDecrypted | null, RootAction>(
    initialState.selectedPatient,
  )
    .handleAction(selectPatientAction, (_, { payload }) => payload)
    .handleAction(
      [logoutAction.request, invalidSessionAction.request],
      () => initialState.selectedPatient,
    ),
});
