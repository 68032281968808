// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { RootState } from '../../../store/rootReducer';
import type { LoginState } from './reducers';

export const loginSelector = ({ login }: RootState): LoginState => login;

export const isLoggedInSelector = ({ login }: RootState): boolean =>
  login.hasValidSession || false;

export const tenantTypeSelector = ({ login }: RootState) => login.tenantType;
