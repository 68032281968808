// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Dispatch } from 'react';
import type { PatientDecrypted } from '../../../api/types';
import type { PatientSearchRequestDecrypted } from '../types';
import { patientSearchActions, selectPatientAction } from './actions';

export const patientSearch = (
  request: PatientSearchRequestDecrypted,
  dispatch: Dispatch<any>,
): void => {
  dispatch(patientSearchActions.request(request));
};

export const patientSelect = (
  patient: PatientDecrypted,
  dispatch: Dispatch<any>,
): void => {
  dispatch(selectPatientAction(patient));
};
