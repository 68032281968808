// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import { map, catchError, switchMap, filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import { ContextNames } from '../../../../../i18n';
import {
  fhirConfigurationGetActions,
  fhirConfigurationSaveActions,
} from './actions';
import { getErrorMessageCode } from '../../../../../i18n/utils';
import {
  getFhirConfiguration$,
  saveFhirConfiguration$,
} from '../../../../../api/services/fhirConfigurationService';
import {
  startGetFhirConfigurationSpan,
  startSaveFhirConfigurationSpan,
  endGetFhirConfigurationSpan,
  endSaveFhirConfigurationSpan,
} from '../../../tracing';
import { showErrorBannerAction } from '../../../../../store/error-banner-state/actions';
import { errors } from '../../../../../store/error-banner-state/errors';

export const saveFhirConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(fhirConfigurationSaveActions.request)),
    tap(() => {
      startSaveFhirConfigurationSpan();
    }),
    switchMap(({ payload }) => {
      return from(saveFhirConfiguration$(payload)).pipe(
        tap(() => {
          endSaveFhirConfigurationSpan();
        }),
        map(() =>
          fhirConfigurationSaveActions.success({
            audience: payload.audience,
            clientId: payload.clientId,
            issuer: payload.issuer,
            identifierSystem: payload.identifierSystem,
            type: payload.type,
            epicRestApiBaseAddress: payload.epicRestApiBaseAddress,
          }),
        ),
        catchError(error => {
          endSaveFhirConfigurationSpan(error);

          return of(
            fhirConfigurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.FHIR_CONFIGURATION,
                'fhir-configuration-save-failed',
              ),
              message:
                'An error occurred while saving the configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.FHIR_CONFIGURATION_SAVE_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const getFhirConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(fhirConfigurationGetActions.request)),
    tap(() => {
      startGetFhirConfigurationSpan();
    }),
    switchMap(() => {
      return from(getFhirConfiguration$()).pipe(
        tap(() => {
          endGetFhirConfigurationSpan();
        }),
        map(configuration =>
          fhirConfigurationGetActions.success(configuration),
        ),
        catchError(error => {
          endGetFhirConfigurationSpan(error);
          return of(
            fhirConfigurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.FHIR_CONFIGURATION,
                'fhir-configuration-get-failed',
              ),
              message:
                'An error occurred while retrieving the configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.FHIR_CONFIGURATION_GET_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const fhirConfigurationEpic = combineEpics(
  saveFhirConfigurationEpic,
  getFhirConfigurationEpic,
);
