// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { StorageKeys as LoginKeys } from './containers/login/constants';

type StorageKey = LoginKeys;

export function saveToStorage(key: StorageKey, value: string): void {
  localStorage.setItem(key, value);
}

export function getFromStorage(key: StorageKey): string | null {
  return localStorage.getItem(key);
}

export function removeFromStorage(key: StorageKey): void {
  localStorage.removeItem(key);
}
