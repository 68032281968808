// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction, createAsyncAction } from 'typesafe-actions';
import type {
  ApiError,
  PatientDecrypted,
  PatientSearchResponseDecrypted,
} from '../../../api/types';
import type { PatientSearchRequestDecrypted } from '../types';

export const patientSearchActions = createAsyncAction(
  'patient/search/PATIENT_SEARCH_REQUEST',
  'patient/search/PATIENT_SEARCH_SUCCESS',
  'patient/search/PATIENT_SEARCH_FAILURE',
  'patient/search/PATIENT_SEARCH_CANCEL',
)<
  PatientSearchRequestDecrypted,
  PatientSearchResponseDecrypted,
  ApiError,
  string | void
>();

export const clearPatientSearchAction = createAction(
  'patient/search/CLEAR_PATIENT_SEARCH',
)<void>();

export const selectPatientAction = createAction(
  'patient/search/SELECT_PATIENT',
)<PatientDecrypted>();
