// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  tracer,
  SpanNames,
  endRootSpan,
  recordException,
  startRootSpan,
} from '../../tracing';

export function startSearchForPatientSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.SEARCH_FOR_PATIENT,
    SpanNames.ROOT_SPAN,
  );
}

export function endSearchForPatientSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.SEARCH_FOR_PATIENT, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.SEARCH_FOR_PATIENT);
  endRootSpan();
}
