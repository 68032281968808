// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Hl7RuleType } from '@imprivata-cloud/adminapi-client';

export function ruleTypeToString(ruleType: Hl7RuleType | undefined): string {
  switch (ruleType) {
    case Hl7RuleType.PhotoAdded:
    default:
      return 'Photo Added';
  }
}

export function stringToRuleType(ruleTypeString: string): Hl7RuleType {
  switch (ruleTypeString) {
    case 'Photo Added':
    default:
      return Hl7RuleType.PhotoAdded;
  }
}
