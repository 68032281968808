// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type { RootAction } from '../../../store/rootAction';
import { reportActions } from './actions';
import { invalidSessionAction } from '../../login/store/actions';

export interface ReportsState {
  processing: boolean;
  error: string | null;
}

export const initialState: ReportsState = {
  processing: false,
  error: null,
};

export const reportsReducer = combineReducers<ReportsState>({
  processing: createReducer<boolean>(initialState.processing)
    .handleAction([reportActions.request], () => true)
    .handleAction(
      [
        reportActions.success,
        reportActions.failure,
        invalidSessionAction.request,
      ],
      () => false,
    ),
  error: createReducer<string | null, RootAction>(initialState.error)
    .handleAction(
      [
        reportActions.request,
        reportActions.success,
        invalidSessionAction.request,
      ],
      () => null,
    )
    .handleAction(
      [reportActions.failure],
      (_, { payload }) => payload.code || null,
    ),
});
