// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { BannerProps } from '@imprivata-cloud/components';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const showErrorBannerAction = createAsyncAction(
  'show-error-banner/REQUEST',
  'show-error-banner/SUCCESS',
  'show-error-banner/FAILURE',
)<BannerProps, BannerProps, void>();

export const deleteErrorBannerItemAction = createAction(
  'delete-error-banner-item',
)<BannerProps>();
