// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type {
  DashboardTrendDataResponse,
  GetDashboardTrendDataRequest,
} from '@imprivata-cloud/adminapi-client';
import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { adminApiClient, promiseToObservable } from '../client';

export function getDashboardTrendData$(
  request: GetDashboardTrendDataRequest,
): Observable<DashboardTrendDataResponse> {
  return promiseToObservable(
    adminApiClient.getDashboardTrendData(request),
  ).pipe(map(res => res));
}
