// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../components/page-layout/PageLayout';
import SetTitle from '../../utils/DynamicTitleHelper';
import DashboardView from './components/dashboard-view/DashboardView';

const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.dashboard'));

  return (
    <PageLayout
      title={t('navigation.dashboard')}
      data-testid="dashboard-page-layout"
      className="overflow-hidden"
    >
      <DashboardView />
    </PageLayout>
  );
};

export default Dashboard;
