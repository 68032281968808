// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { Button, Col, Row } from 'antd';
import EmptyStateSvg from '../../../../../../assets/svg/empty-state.svg?react';
import classes from './EmptyState.module.less';

interface IEmptyState {
  content: string;
  actionText: string;
  onActionClick: () => void;
}

const EmptyState: React.FC<IEmptyState> = ({
  content,
  actionText,
  onActionClick,
}) => {
  return (
    <>
      <Row justify="center">
        <Col span={6}>
          <EmptyStateSvg />
        </Col>
      </Row>
      <Row justify="center">
        <Col flex="auto">
          <div className={classes.fontLg}>{content}</div>
          <Button
            type="link"
            size="large"
            className={classes.centerButton}
            onClick={onActionClick}
          >
            {actionText}
          </Button>
        </Col>
      </Row>
    </>
  );
};
export default EmptyState;
