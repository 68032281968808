// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { RootState } from '../../../store/rootReducer';

export const patientDeletionErrorSelector = ({
  patientDetails,
}: RootState): string | null => patientDetails.deletionError;

export const patientDeletionOccurredSelector = ({
  patientDetails,
}: RootState): boolean => patientDetails.isDeletionOccurred;
