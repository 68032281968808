// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type {
  Hl7SystemConfiguration,
  Hl7RuleConfiguration,
  Hl7MessageSummary,
} from '@imprivata-cloud/adminapi-client';
import { createSelector } from 'reselect';
import type { RootState } from '../../../../../store/rootReducer';
import type { Hl7MessageCount, Hl7MessageState } from './reducers';

export const isLoadingSelector = ({ hl7Configuration }: RootState): boolean =>
  hl7Configuration.loading;

export const systemConfigurationSelector = ({
  hl7Configuration,
}: RootState): Hl7SystemConfiguration | null =>
  hl7Configuration.systemConfiguration;

export const ruleConfigurationSelector = ({
  hl7Configuration,
}: RootState): Hl7RuleConfiguration[] | null =>
  hl7Configuration.ruleConfigurations;

export const successMessagesSelector = ({
  hl7Configuration,
}: RootState): Hl7MessageCount | null => hl7Configuration.successMessages;

export const successMessagesLoadingSelector = createSelector(
  successMessagesSelector,
  successMessages =>
    successMessages === null ? true : successMessages.loading,
);

export const successMessagesCountSelector = createSelector(
  successMessagesSelector,
  successMessages => (successMessages === null ? 0 : successMessages.count),
);

export const erroredMessagesSelector = ({
  hl7Configuration,
}: RootState): Hl7MessageCount | null => hl7Configuration.erroredMessages;

export const erroredMessagesLoadingSelector = createSelector(
  erroredMessagesSelector,
  erroredMessages =>
    erroredMessages === null ? true : erroredMessages.loading,
);

export const erroredMessagesCountSelector = createSelector(
  erroredMessagesSelector,
  erroredMessages => (erroredMessages === null ? 0 : erroredMessages.count),
);

export const messageSearchLoadingSelector = ({
  hl7MessageSearch,
}: RootState): boolean => hl7MessageSearch.loading;

export const unsentMessagesSelector = ({
  hl7Configuration,
}: RootState): Hl7MessageCount | null => hl7Configuration.unsentMessages;

export const unsentMessagesLoadingSelector = createSelector(
  unsentMessagesSelector,
  unsentMessages => (unsentMessages === null ? true : unsentMessages.loading),
);

export const unsentMessagesCountSelector = createSelector(
  unsentMessagesSelector,
  unsentMessages => (unsentMessages === null ? 0 : unsentMessages.count),
);

const searchResultSelector = ({
  hl7MessageSearch,
}: RootState): {
  pageNumber: number;
  pageSize: number;
  totalMessages: number;
  messages: Hl7MessageSummary[];
} =>
  hl7MessageSearch && hl7MessageSearch.results
    ? {
        pageNumber: hl7MessageSearch.results.pageNumber || 0,
        pageSize: hl7MessageSearch.results.pageSize || 0,
        totalMessages: hl7MessageSearch.results.totalMessages || 0,
        messages: hl7MessageSearch.results.messages || [],
      }
    : {
        pageNumber: 0,
        pageSize: 0,
        totalMessages: 0,
        messages: [],
      };

export const messagesSelector = ({
  hl7MessageSearch,
}: RootState): Hl7MessageState[] => hl7MessageSearch.messages;

export const messageSearchResultsSelector = createSelector(
  searchResultSelector,
  messagesSelector,
  (result, messagesState) => {
    return result.messages.map(summary => {
      const messageDetails = messagesState.find(
        message => message.id === summary.id,
      );
      return {
        ...summary,
        details: messageDetails ? messageDetails.message : undefined,
      };
    });
  },
);

export const searchResultsCountSelector = createSelector(
  searchResultSelector,
  result => {
    return {
      totalCount: result.totalMessages,
      pageCount:
        result.pageSize > 0
          ? Math.ceil(result.totalMessages / result.pageSize)
          : 0,
      startCount: result.messages.length
        ? (result.pageNumber - 1) * result.pageSize + 1
        : 0,
      endCount:
        result.messages.length < result.pageSize
          ? result.pageSize * (result.pageNumber - 1) + result.messages.length
          : result.pageSize * result.pageNumber,
    };
  },
);
