// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { Button, Card, Col, Row, Space } from 'antd';
import type { GetHl7MessageResponse } from '@imprivata-cloud/adminapi-client';
import { useState } from 'react';
import moment from 'moment';
import Icon from '@ant-design/icons';
import classes from './Hl7MessageRow.module.less';
import Hl7MessageDetails from '../hl7-message-details/Hl7MessageDetails';
import upArrow from '../../../../../../assets/svg/up-arrow.svg?react';
import downArrow from '../../../../../../assets/svg/down-arrow.svg?react';
import successIcon from '../../../../../../assets/svg/success.svg?react';
import errorIcon from '../../../../../../assets/svg/error.svg?react';
import unsentIcon from '../../../../../../assets/svg/unsent-message.svg?react';

interface IHl7MessageRow {
  id: string;
  status: string;
  timestamp: moment.Moment;
  eventDisplayName: string;
  messageDetails?: GetHl7MessageResponse | null;
  onLoadMessageDetails: (id: string) => void;
}

const Hl7MessageRow: React.FC<IHl7MessageRow> = ({
  id,
  status,
  timestamp,
  eventDisplayName,
  messageDetails,
  onLoadMessageDetails,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onExpandClick = (id: string) => {
    setIsOpen(!isOpen);

    if (!messageDetails) {
      onLoadMessageDetails(id);
    }
  };

  const statusIcon = () => {
    switch (status) {
      case 'success':
        return successIcon;
      case 'error':
        return errorIcon;
      default:
        return unsentIcon;
    }
  };

  return (
    <>
      <Card size="small">
        <Row wrap={false}>
          <Col flex="auto">
            <Row gutter={32}>
              <Col flex="120px" className={classes[status]}>
                <Space size={4}>
                  <Icon component={statusIcon()}></Icon>
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </Space>
              </Col>
              <Col flex="0 1 auto">
                {moment(timestamp).format('YYYY-MM-DD HH:mm:ss')}
              </Col>
              <Col flex="auto">{eventDisplayName}</Col>
            </Row>
          </Col>
          <Col flex="0 1 auto" className={classes.arrowContainer}>
            <Button
              type="text"
              size="small"
              onClick={() => {
                onExpandClick(id);
              }}
            >
              <Icon component={isOpen ? upArrow : downArrow} />
            </Button>
          </Col>
        </Row>
      </Card>
      <Hl7MessageDetails
        message={messageDetails}
        show={isOpen}
        className={classes.messageDetails}
      ></Hl7MessageDetails>
    </>
  );
};
export default Hl7MessageRow;
