// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createAsyncAction } from 'typesafe-actions';
import type {
  FhirConfiguration,
  SaveFhirConfigurationRequest,
} from '@imprivata-cloud/adminapi-client';
import type { ApiError } from '../../../../../api/types';

export const fhirConfigurationGetActions = createAsyncAction(
  'settings/fhirConfiguration/GET_FHIR_CONFIGURATION_REQUEST',
  'settings/fhirConfiguration/GET_FHIR_CONFIGURATION_SUCCESS',
  'settings/fhirConfiguration/GET_FHIR_CONFIGURATION_FAILURE',
  'settings/fhirConfiguration/GET_FHIR_CONFIGURATION_CANCEL',
)<void, FhirConfiguration | null, ApiError, string | void>();

export const fhirConfigurationSaveActions = createAsyncAction(
  'settings/fhirConfiguration/SAVE_FHIR_CONFIGURATION_REQUEST',
  'settings/fhirConfiguration/SAVE_FHIR_CONFIGURATION_SUCCESS',
  'settings/fhirConfiguration/SAVE_FHIR_CONFIGURATION_FAILURE',
  'settings/fhirConfiguration/SAVE_FHIR_CONFIGURATION_CANCEL',
)<
  SaveFhirConfigurationRequest,
  FhirConfiguration | null,
  ApiError,
  string | void
>();
