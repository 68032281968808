// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type {
  PatientDecrypted,
  PatientSearchResponseDecrypted,
} from '../../../api/types';
import type { RootState } from '../../../store/rootReducer';

export const isPatientSearchingSelector = ({
  patientSearch,
}: RootState): boolean => patientSearch.searching;

export const patientSearchResponseSelector = ({
  patientSearch,
}: RootState): PatientSearchResponseDecrypted | null =>
  patientSearch.searchResponse;

export const patientSearchErrorSelector = ({
  patientSearch,
}: RootState): string | null => patientSearch.error;

export const patientSelectedSelector = ({
  patientSearch,
}: RootState): PatientDecrypted | null => patientSearch.selectedPatient;
