// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useSelector } from 'react-redux';
import type { RouteProps } from 'react-router-dom';
import { loginSelector } from '../../containers/login/store/selectors';
import { RedirectWithQuery, RouteWithQuery } from '../../utils/routingHelpers';
import { LOGIN_ROUTE } from '../../routers/route-names';
import { QueryParamKey } from '../../containers/login/constants';

export type ProtectedRouteProps = RouteProps;

export default function ProtectedRoute({
  ...routeProps
}: ProtectedRouteProps): JSX.Element {
  const { hasValidSession, tenantId } = useSelector(loginSelector);

  if (hasValidSession) {
    return <RouteWithQuery {...routeProps} />;
  } else {
    const redirectUrl =
      LOGIN_ROUTE + (tenantId ? `?${QueryParamKey.TENANT_ID}=${tenantId}` : '');

    return <RedirectWithQuery to={redirectUrl} />;
  }
}
