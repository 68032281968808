// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { loginSelector } from './selectors';

export const useQueryParams = (): URLSearchParams => {
  const location = useLocation();
  return useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
};

export const useLoginHook = (): {
  permissionError?: string;
  username?: string;
  storedURL?: string;
  hasValidSession: boolean;
  errorToDisplay?: string;
} => {
  const {
    permissionError,
    username,
    storedURL,
    hasValidSession,
    preloginMessageKey,
  } = useSelector(loginSelector);

  const errorToDisplay = preloginMessageKey;

  return {
    permissionError,
    username,
    storedURL,
    hasValidSession: !!hasValidSession,
    errorToDisplay,
  };
};
