// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Dispatch } from 'react';
import { reportActions } from './actions';

export const runReport = (
  name: string,
  startDate: moment.Moment,
  endDate: moment.Moment | null | undefined,
  timeZoneId: string | null | undefined,
  dispatch: Dispatch<any>,
): void => {
  dispatch(reportActions.request({ name, startDate, endDate, timeZoneId }));
};
