// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import './NavigationMenu.less';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';

interface Props {
  styles?: { [key: string]: string };
  componentEffectFunction: () => void;
  defaultSelectedKeys: string[] | undefined;
  items: ItemType[] | undefined;
}

const NavigationMenu: React.FC<Props> = ({
  styles,
  componentEffectFunction,
  defaultSelectedKeys,
  items,
}) => {
  const location = useLocation();

  useEffect(() => {
    componentEffectFunction();
  }, [componentEffectFunction]);

  return (
    <Menu
      theme="light"
      defaultSelectedKeys={defaultSelectedKeys}
      selectedKeys={location.pathname.split('/') || ['']}
      mode="inline"
      className="navigation"
      style={{ ...styles }}
      items={items}
    />
  );
};

export default NavigationMenu;
