// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createAsyncAction } from 'typesafe-actions';
import type { DashboardTrendDataResponse } from '@imprivata-cloud/adminapi-client';
import type { ApiError } from '../../../api/types';
import type { DashboardTrendDataRequest } from '../types';

export const trendDataChartActions = createAsyncAction(
  'dashboard/GET_TREND_DATA_CHART_REQUEST',
  'dashboard/GET_TREND_DATA_CHART_SUCCESS',
  'dashboard/GET_TREND_DATA_CHART_FAILURE',
  'dashboard/GET_TREND_DATA_CHART_CANCEL',
)<
  DashboardTrendDataRequest,
  DashboardTrendDataResponse,
  ApiError,
  string | void
>();
