// Copyright 2022, Imprivata, Inc.  All rights reserved.
import type { Hl7RuleConfiguration } from '@imprivata-cloud/adminapi-client';
import { Button, Card, Col, Row, Space, Typography } from 'antd';
import Icon from '@ant-design/icons/lib/components/Icon';
import { useTranslation } from 'react-i18next';
import ConfigureIcon from '../../../../../../assets/svg/configure.svg?react';
import classes from './Hl7RuleConfigurationCardList.module.less';
import { ruleTypeToString } from '../../utils/RuleTypeUtils';

const { Text } = Typography;

interface IRuleConfigurationCard {
  configurations: Hl7RuleConfiguration[];
  onConfigureAction: (ruleId: string | null | undefined) => void;
  onDeleteAction: (ruleId: string | null | undefined) => void;
}

const Hl7RuleConfigurationCardList: React.FC<IRuleConfigurationCard> = ({
  configurations,
  onConfigureAction,
  onDeleteAction,
}) => {
  const { t } = useTranslation();

  const GenerateCardFromConfiguration = (config: Hl7RuleConfiguration) => {
    return (
      <Card key={config.id} style={{ marginBottom: '8px' }}>
        <Row align="middle">
          <Col flex="auto">
            <Space direction="vertical" align="start" size="small">
              <Text className={classes.displayName}>{config.displayName}</Text>
              <Text className={classes.ruleType}>
                {ruleTypeToString(config.ruleType)}
              </Text>
            </Space>
          </Col>
          <Col>
            <Button
              type="link"
              onClick={() => {
                onConfigureAction(config.id);
              }}
            >
              <Icon component={ConfigureIcon}></Icon>
              {t('hl7-configuration.rule-configuration.edit-btn')}
            </Button>
          </Col>
          <Col>
            <Button
              type="link"
              onClick={() => {
                onDeleteAction(config.id);
              }}
            >
              {t('hl7-configuration.rule-configuration.delete-btn')}
            </Button>
          </Col>
        </Row>
      </Card>
    );
  };

  const cards = configurations.map(GenerateCardFromConfiguration);

  return <Col flex={9}>{cards}</Col>;
};
export default Hl7RuleConfigurationCardList;
