// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { Switch } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Banner } from '@imprivata-cloud/components';
import SiteLayout from './containers/site-layout/SiteLayout';
import {
  DASHBOARD_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
} from './routers/route-names';
import { ejectInterceptors } from './api/interceptors';
import { RouteWithQuery } from './utils/routingHelpers';
import Logout from './containers/logout/Logout';
import ProtectedRoute from './components/protected-route/ProtectedRoute';
import LoginRoutes from './containers/login/LoginRoutes';
import { errorBannerSelector } from './store/error-banner-state/selectors';
import {
  isLoggedInSelector,
  tenantTypeSelector,
} from './containers/login/store/selectors';
import { getTenantTypeAction } from './containers/login/store/actions';
import { deleteErrorBannerItemAction } from './store/error-banner-state/actions';

function App(): JSX.Element {
  const { error } = useSelector(errorBannerSelector);
  const isLoggedIn = useSelector(isLoggedInSelector, () => true);
  const tenantType = useSelector(tenantTypeSelector, () => true);

  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      Banner(error);
      if (error?.duration) {
        setTimeout(() => {
          dispatch(deleteErrorBannerItemAction(error));
        }, error.duration * 1000);
      }
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (isLoggedIn && tenantType === undefined) {
      dispatch(getTenantTypeAction.request());
    }
    return () => {
      ejectInterceptors();
    };
  }, [dispatch, isLoggedIn, tenantType]);

  return (
    <Switch>
      <RouteWithQuery
        path={LOGIN_ROUTE}
        component={LoginRoutes}
      ></RouteWithQuery>
      <RouteWithQuery exact path={LOGOUT_ROUTE} component={Logout} />
      <ProtectedRoute path={DASHBOARD_ROUTE} component={SiteLayout} />
    </Switch>
  );
}

export default App;
