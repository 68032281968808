// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map, switchMap, catchError, filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import { ContextNames } from '../../../i18n';
import { getErrorMessageCode } from '../../../i18n/utils';
import { patientSearchActions } from './actions';
import { searchPatient$ } from '../../../api/services/patientService';
import { endSearchForPatientSpan, startSearchForPatientSpan } from '../tracing';
import { showErrorBannerAction } from '../../../store/error-banner-state/actions';
import { errors } from '../../../store/error-banner-state/errors';

export const patientSearchEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(patientSearchActions.request)),
    tap(() => {
      startSearchForPatientSpan();
    }),
    switchMap(({ payload }) => {
      return searchPatient$(payload).pipe(
        tap(() => {
          endSearchForPatientSpan();
        }),
        map(response => patientSearchActions.success(response)),
        catchError(error => {
          endSearchForPatientSpan(error);
          return of(
            patientSearchActions.failure({
              code: getErrorMessageCode(
                ContextNames.PATIENT_SEARCH,
                'patient-search-failed',
              ),
              message:
                'An error occurred while searching for the patient. Please try again.',
            }),
            showErrorBannerAction.request(errors.PATIENT_SEARCH_FAILED()),
          );
        }),
      );
    }),
  );

export const patientSearchEpics = combineEpics(patientSearchEpic);
