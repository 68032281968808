// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import { useLocation } from 'react-router-dom';
import PageLayout from '../../components/page-layout/PageLayout';
import SetTitle from '../../utils/DynamicTitleHelper';
import classes from './Reports.module.less';
import pageLayoutClasses from '../../components/page-layout/PageLayout.module.less';

import ReportsContextProvider from './components/reports-context-provider/ReportsContextProvider';
import ReportsRoutes from './ReportsRoutes';
import NavigationMenu from '../../components/navigation-menu/NavigationMenu';
import { reportsRoutes } from '../../routers/route-names';
import { LinkWithQuery, redirectWithQuery } from '../../utils/routingHelpers';

const { Content, Sider } = Layout;

enum ReportsMenuItemKeys {
  ActivityExtract = 'activity-extract',
  AuditExtract = 'audit-extract',
}
const Reports: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.reports'));
  const location = useLocation();
  const navigationMenuEffectFunction = () => {
    //This is for opening first tab of reports menu by default
    if (location.pathname === '/reports') {
      redirectWithQuery(`/reports/${ReportsMenuItemKeys.ActivityExtract}`);
    }
  };

  return (
    <PageLayout
      title={t('navigation.reports')}
      className={classes.reports}
      contentClassName={classes.content}
    >
      <ReportsContextProvider>
        <Layout className={classes.layout}>
          <Sider className={classes.sider}>
            <NavigationMenu
              componentEffectFunction={navigationMenuEffectFunction}
              defaultSelectedKeys={[ReportsMenuItemKeys.ActivityExtract]}
              items={[
                {
                  key: ReportsMenuItemKeys.ActivityExtract,
                  label: (
                    <LinkWithQuery
                      data-testid="reports-menu--activity-extract"
                      to={reportsRoutes.ACTIVITY_EXTRACT}
                      tabIndex={-1}
                    >
                      {t('navigation.activity-extract')}
                    </LinkWithQuery>
                  ),
                },
                {
                  key: ReportsMenuItemKeys.AuditExtract,
                  label: (
                    <LinkWithQuery
                      data-testid="reports-menu--audit-extract"
                      to={reportsRoutes.AUDIT_EXTRACT}
                      tabIndex={-1}
                    >
                      {t('navigation.audit-extract')}
                    </LinkWithQuery>
                  ),
                },
              ]}
            />
          </Sider>
          <Content className={pageLayoutClasses.pageContent}>
            <ReportsRoutes />
          </Content>
        </Layout>
      </ReportsContextProvider>
    </PageLayout>
  );
};

export default Reports;
