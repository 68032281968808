// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  tracer,
  SpanNames,
  endRootSpan,
  recordException,
  startRootSpan,
} from '../../tracing';

export function startGetEndpointInstallerDataSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_ENDPOINT_INSTALLER_DATA,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetEndpointInstallerDataSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_ENDPOINT_INSTALLER_DATA, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_ENDPOINT_INSTALLER_DATA);
  endRootSpan();
}
