// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { FileResponse } from '@imprivata-cloud/adminapi-client';
import { createAsyncAction } from 'typesafe-actions';
import type { ApiError } from '../../../api/types';

export const reportActions = createAsyncAction(
  'reports/RUN_REPORT_REQUEST',
  'reports/RUN_REPORT_SUCCESS',
  'reports/RUN_REPORT_FAILURE',
)<
  {
    name: string;
    startDate: moment.Moment;
    endDate: moment.Moment | null | undefined;
    timeZoneId: string | null | undefined;
  },
  FileResponse,
  ApiError
>();
