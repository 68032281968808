// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { FileResponse } from '@imprivata-cloud/adminapi-client';
import type { Observable } from 'rxjs';
import { adminApiClient, promiseToObservable } from '../client';

export function runReport$(
  name: string,
  startDate: moment.Moment,
  endDate: moment.Moment | null | undefined,
  timeZoneId: string | null | undefined,
): Observable<FileResponse> {
  return promiseToObservable(
    adminApiClient.runReport(name, startDate, endDate, timeZoneId),
  );
}
