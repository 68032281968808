// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createAsyncAction } from 'typesafe-actions';
import type {
  GetHl7MessageResponse,
  Hl7RuleConfiguration,
  Hl7SystemConfiguration,
  SaveHl7RuleConfigurationRequest,
  SaveHl7SystemConfigurationRequest,
  SearchHl7MessagesResponse,
} from '@imprivata-cloud/adminapi-client';
import type moment from 'moment';
import type { ApiError } from '../../../../../api/types';

export const hl7SystemConfigurationGetActions = createAsyncAction(
  'integrations/hl7Configuration/system/GET_HL7_SYSTEM_CONFIGURATION_REQUEST',
  'integrations/hl7Configuration/system/GET_HL7_SYSTEM_CONFIGURATION_SUCCESS',
  'integrations/hl7Configuration/system/GET_HL7_SYSTEM_CONFIGURATION_FAILURE',
  'integrations/hl7Configuration/system/GET_HL7_SYSTEM_CONFIGURATION_CANCEL',
)<void, Hl7SystemConfiguration | null, ApiError, string | void>();

export const hl7SystemConfigurationSaveActions = createAsyncAction(
  'integrations/hl7Configuration/system/SAVE_HL7_SYSTEM_CONFIGURATION_REQUEST',
  'integrations/hl7Configuration/system/SAVE_HL7_SYSTEM_CONFIGURATION_SUCCESS',
  'integrations/hl7Configuration/system/SAVE_HL7_SYSTEM_CONFIGURATION_FAILURE',
  'integrations/hl7Configuration/system/SAVE_HL7_SYSTEM_CONFIGURATION_CANCEL',
)<
  SaveHl7SystemConfigurationRequest,
  Hl7SystemConfiguration | null,
  ApiError,
  string | void
>();

export const hl7SystemConfigurationDeleteActions = createAsyncAction(
  'integrations/hl7Configuration/system/DELETE_HL7_SYSTEM_CONFIGURATION_REQUEST',
  'integrations/hl7Configuration/system/DELETE_HL7_SYSTEM_CONFIGURATION_SUCCESS',
  'integrations/hl7Configuration/system/DELETE_HL7_SYSTEM_CONFIGURATION_FAILURE',
  'integrations/hl7Configuration/system/DELETE_HL7_SYSTEM_CONFIGURATION_CANCEL',
)<void, void, ApiError, string | void>();

export const hl7RuleConfigurationGetActions = createAsyncAction(
  'integrations/hl7Configuration/rule/GET_HL7_RULE_CONFIGURATION_REQUEST',
  'integrations/hl7Configuration/rule/GET_HL7_RULE_CONFIGURATION_SUCCESS',
  'integrations/hl7Configuration/rule/GET_HL7_RULE_CONFIGURATION_FAILURE',
  'integrations/hl7Configuration/rule/GET_HL7_RULE_CONFIGURATION_CANCEL',
)<void, Hl7RuleConfiguration[] | null, ApiError, string | void>();

export const hl7RuleConfigurationSaveActions = createAsyncAction(
  'integrations/hl7Configuration/rule/SAVE_HL7_RULE_CONFIGURATION_REQUEST',
  'integrations/hl7Configuration/rule/SAVE_HL7_RULE_CONFIGURATION_SUCCESS',
  'integrations/hl7Configuration/rule/SAVE_HL7_RULE_CONFIGURATION_FAILURE',
  'integrations/hl7Configuration/rule/SAVE_HL7_RULE_CONFIGURATION_CANCEL',
)<
  SaveHl7RuleConfigurationRequest,
  Hl7RuleConfiguration | null,
  ApiError,
  string | void
>();

export const hl7RuleConfigurationDeleteActions = createAsyncAction(
  'integrations/hl7Configuration/rule/DELETE_HL7_RULE_CONFIGURATION_REQUEST',
  'integrations/hl7Configuration/rule/DELETE_HL7_RULE_CONFIGURATION_SUCCESS',
  'integrations/hl7Configuration/rule/DELETE_HL7_RULE_CONFIGURATION_FAILURE',
  'integrations/hl7Configuration/rule/DELETE_HL7_RULE_CONFIGURATION_CANCEL',
)<string, void, ApiError, string | void>();

export const hl7SuccessMessageCountActions = createAsyncAction(
  'integrations/hl7/messages/GET_HL7_SUCCESS_MESSAGE_COUNT_REQUEST',
  'integrations/hl7/messages/GET_HL7_SUCCESS_MESSAGE_COUNT_SUCCESS',
  'integrations/hl7/messages/GET_HL7_SUCCESS_MESSAGE_COUNT_FAILURE',
  'integrations/hl7/messages/GET_HL7_SUCCESS_MESSAGE_COUNT_CANCEL',
)<moment.Moment, number, ApiError, string | void>();

export const hl7ErroredMessageCountActions = createAsyncAction(
  'integrations/hl7/messages/GET_HL7_ERRORED_MESSAGE_COUNT_REQUEST',
  'integrations/hl7/messages/GET_HL7_ERRORED_MESSAGE_COUNT_SUCCESS',
  'integrations/hl7/messages/GET_HL7_ERRORED_MESSAGE_COUNT_FAILURE',
  'integrations/hl7/messages/GET_HL7_ERRORED_MESSAGE_COUNT_CANCEL',
)<moment.Moment, number, ApiError, string | void>();

export const hl7UnsentMessageCountActions = createAsyncAction(
  'integrations/hl7/messages/GET_HL7_UNSENT_MESSAGE_COUNT_REQUEST',
  'integrations/hl7/messages/GET_HL7_UNSENT_MESSAGE_COUNT_SUCCESS',
  'integrations/hl7/messages/GET_HL7_UNSENT_MESSAGE_COUNT_FAILURE',
  'integrations/hl7/messages/GET_HL7_UNSENT_MESSAGE_COUNT_CANCEL',
)<moment.Moment, number, ApiError, string | void>();

export const hl7MessageSearchActions = createAsyncAction(
  'integrations/hl7/messages/SEARCH_REQUEST',
  'integrations/hl7/messages/SEARCH_SUCCESS',
  'integrations/hl7/messages/SEARCH_FAILURE',
  'integrations/hl7/messages/SEARCH_CANCEL',
)<
  {
    startDate: moment.Moment;
    endDate?: moment.Moment;
    messageStatuses: string[];
    pageNumber: number;
    pageSize: number;
  },
  SearchHl7MessagesResponse,
  ApiError,
  string | void
>();

export const hl7MessageActions = createAsyncAction(
  'integrations/hl7/messages/GET_MESSAGE_REQUEST',
  'integrations/hl7/messages/GET_MESSAGE_SUCCESS',
  'integrations/hl7/messages/GET_MESSAGE_FAILURE',
  'integrations/hl7/messages/GET_MESSAGE_CANCEL',
)<string, GetHl7MessageResponse, ApiError & { id: string }, string | void>();
