// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type { RootAction } from '../../../store/rootAction';
import { clearPatientDeleteAction, patientDeleteActions } from './actions';
import {
  patientSearchActions,
  selectPatientAction,
} from '../../patient-search/store/actions';
import { invalidSessionAction } from '../../login/store/actions';

export interface PatientDetailsState {
  deletionError: string | null;
  isDeletionOccurred: boolean;
}

export const initialState: PatientDetailsState = {
  deletionError: null,
  isDeletionOccurred: false,
};

export const patientDetailsReducer = combineReducers<PatientDetailsState>({
  deletionError: createReducer<string | null, RootAction>(
    initialState.deletionError,
  )
    .handleAction(
      [
        patientDeleteActions.request,
        patientDeleteActions.success,
        invalidSessionAction.request,
        clearPatientDeleteAction,
      ],
      () => null,
    )
    .handleAction(
      [patientDeleteActions.failure],
      (_, { payload }) => payload.code || null,
    )
    .handleAction([patientDeleteActions.cancel], () => 'cancelled'),
  isDeletionOccurred: createReducer<boolean, RootAction>(
    initialState.isDeletionOccurred,
  )
    .handleAction(
      [patientDeleteActions.success, patientDeleteActions.failure],
      () => true,
    )
    .handleAction(
      [
        patientSearchActions.request,
        selectPatientAction,
        invalidSessionAction.request,
        clearPatientDeleteAction,
      ],
      () => false,
    ),
});
