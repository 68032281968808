// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Col, Divider, Row, Typography } from 'antd';
import Layout, { Content } from 'antd/lib/layout/layout';
import {
  CheckSquareOutlined,
  CopyOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import type { GetEndpointInstallationDataResponse } from '@imprivata-cloud/adminapi-client';
import type { FormEvent } from 'react';
import classes from './EndpointInstaller.module.less';
import { copyToClipboard } from '../../utils/utils';
import SetTitle from '../../utils/DynamicTitleHelper';
import PageLayout from '../../components/page-layout/PageLayout';
import { getEndpointInstallerData$ } from '../../api/services/endpointInstallerService';
import {
  endGetEndpointInstallerDataSpan,
  startGetEndpointInstallerDataSpan,
} from './tracing';

const EndpointInstaller: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.installers'));

  const [isCopied, setCopied] = useState<boolean>(false);
  const [isCommandLineCopied, setCommandLineCopied] = useState<boolean>(false);
  const [endpointInstallerData, setEndpointInstallerData] =
    useState<GetEndpointInstallationDataResponse | null>(null);

  const copyInstallInfo = (e: FormEvent) => {
    if (endpointInstallerData?.encodedInstallInfo) {
      copyToClipboard(e, endpointInstallerData.encodedInstallInfo);

      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  const copyCommandLine = (e: FormEvent) => {
    if (endpointInstallerData?.commandLine) {
      copyToClipboard(e, endpointInstallerData.commandLine);

      setCommandLineCopied(true);
      setTimeout(() => {
        setCommandLineCopied(false);
      }, 3000);
    }
  };

  const useEndointInstallerDataMountEffect = () =>
    useEffect(() => {
      startGetEndpointInstallerDataSpan();
      getEndpointInstallerData$().then(response => {
        endGetEndpointInstallerDataSpan();
        setEndpointInstallerData(response);
      });
    }, []);
  useEndointInstallerDataMountEffect();

  return (
    <PageLayout title={t('navigation.installers')}>
      <Layout>
        <Content style={{ padding: '10px' }}>
          <Row>
            <Col>
              <Card>
                <Typography.Title
                  data-testid="installer--download-label"
                  level={4}
                >
                  Download endpoint installer
                  <Link
                    data-testid="installer--download-link"
                    href={endpointInstallerData?.endpointInstallerUrl}
                    style={{ marginLeft: 5 }}
                  >
                    <DownloadOutlined />
                  </Link>
                </Typography.Title>
                <Divider></Divider>
                <Typography.Title data-testid="installer--copy-label" level={4}>
                  Copy installation key
                  {isCopied ? (
                    <>
                      <CheckSquareOutlined
                        data-testid="installer--copy-checked-icon"
                        className={classes.mouseHover}
                        style={{ marginLeft: 5 }}
                      />
                      <Typography.Paragraph
                        data-testid="installer--copied-label"
                        className={classes.copyText}
                      >
                        Installation key copied
                      </Typography.Paragraph>
                    </>
                  ) : (
                    <CopyOutlined
                      data-testid="installer--copy-icon"
                      className={classes.mouseHover}
                      style={{ marginLeft: 5 }}
                      onClick={e => copyInstallInfo(e)}
                    />
                  )}
                </Typography.Title>
                <Divider></Divider>
                <Typography.Title data-testid="command--copy-label" level={4}>
                  Copy example command line syntax with installation key
                  {isCommandLineCopied ? (
                    <>
                      <CheckSquareOutlined
                        data-testid="command--copy-checked-icon"
                        className={classes.mouseHover}
                        style={{ marginLeft: 5 }}
                      />
                      <Typography.Paragraph
                        data-testid="command--copied-label"
                        className={classes.copyText}
                      >
                        Example command line copied
                      </Typography.Paragraph>
                    </>
                  ) : (
                    <CopyOutlined
                      data-testid="command--copy-icon"
                      className={classes.mouseHover}
                      style={{ marginLeft: 5 }}
                      onClick={e => copyCommandLine(e)}
                    />
                  )}
                </Typography.Title>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PageLayout>
  );
};

export default EndpointInstaller;
