// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { from, of } from 'rxjs';
import {
  map,
  catchError,
  switchMap,
  filter,
  tap,
  takeUntil,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import moment from 'moment';
import type { Epic } from 'redux-observable';
import { ContextNames } from '../../../../../i18n';
import {
  hl7ErroredMessageCountActions,
  hl7MessageActions,
  hl7MessageSearchActions,
  hl7RuleConfigurationDeleteActions,
  hl7RuleConfigurationGetActions,
  hl7RuleConfigurationSaveActions,
  hl7SystemConfigurationDeleteActions,
  hl7SystemConfigurationGetActions,
  hl7SystemConfigurationSaveActions,
  hl7UnsentMessageCountActions,
  hl7SuccessMessageCountActions,
} from './actions';
import { getErrorMessageCode } from '../../../../../i18n/utils';
import {
  deleteHl7RuleConfiguration$,
  deleteHl7SystemConfiguration$,
  getHl7RuleConfigurationsByTenant$,
  getHl7SystemConfiguration$,
  getHl7MessageCount$,
  saveHl7RuleConfiguration$,
  saveHl7SystemConfiguration$,
  searchHl7Messages$,
  getHl7Message$,
} from '../../../../../api/services/hl7ConfigurationService';
import { redirectWithQuery } from '../../../../../utils/routingHelpers';
import { integrationsRoutes } from '../../../../../routers/route-names';
import {
  startDeleteHl7RuleConfigurationSpan,
  startDeleteHl7SystemConfigurationSpan,
  startGetHl7RuleConfigurationsSpan,
  startGetHl7SystemConfigurationSpan,
  startSaveHl7RuleConfigurationSpan,
  startSaveHl7SystemConfigurationSpan,
  endDeleteHl7RuleConfigurationSpan,
  endDeleteHl7SystemConfigurationSpan,
  endGetHl7RuleConfigurationsSpan,
  endGetHl7SystemConfigurationSpan,
  endSaveHl7RuleConfigurationSpan,
  endSaveHl7SystemConfigurationSpan,
  startGetHl7ErroredMessageCountSpan,
  endGetHl7ErroredMessageCountSpan,
  startGetHl7MessageSpan,
  endGetHl7MessageSpan,
  startSearchHl7MessagesSpan,
  endSearchHl7MessagesSpan,
  cancelSearchHl7MessagesSpan,
  endGetHl7UnsentMessageCountSpan,
  startGetHl7UnsentMessageCountSpan,
  endGetHl7SuccessMessageCountSpan,
  startGetHl7SuccessMessageCountSpan,
} from '../../../tracing';
import { showErrorBannerAction } from '../../../../../store/error-banner-state/actions';
import { errors } from '../../../../../store/error-banner-state/errors';

export const saveHl7SystemConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7SystemConfigurationSaveActions.request)),
    tap(_ => {
      startSaveHl7SystemConfigurationSpan();
      console.log();
    }),
    switchMap(({ payload }) => {
      return from(saveHl7SystemConfiguration$(payload)).pipe(
        tap(() => {
          endSaveHl7SystemConfigurationSpan();
          redirectWithQuery(integrationsRoutes.HL7_CONFIGURATION);
        }),
        map(() =>
          hl7SystemConfigurationSaveActions.success({
            audience: payload.audience,
            clientId: payload.clientId,
            endpoint: payload.endpoint,
            receivingFacility: payload.receivingFacility,
            receivingApplication: payload.receivingApplication,
          }),
        ),
        catchError(error => {
          endSaveHl7SystemConfigurationSpan(error);
          return of(
            hl7SystemConfigurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.HL7_CONFIGURATION,
                'hl7-configuration--system--save-failed',
              ),
              message:
                'An error occurred while saving the system configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.HL7_CONFIGURATION_SYSTEM_SAVE_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const getHl7SystemConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7SystemConfigurationGetActions.request)),
    tap(() => {
      startGetHl7SystemConfigurationSpan();
    }),
    switchMap(() => {
      return from(getHl7SystemConfiguration$()).pipe(
        tap(() => {
          endGetHl7SystemConfigurationSpan();
        }),
        map(configuration =>
          hl7SystemConfigurationGetActions.success(configuration),
        ),
        catchError(error => {
          endGetHl7SystemConfigurationSpan(error);
          return of(
            hl7SystemConfigurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.HL7_CONFIGURATION,
                'hl7-configuration--system--get-failed',
              ),
              message:
                'An error occurred while retrieving the system configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.HL7_CONFIGURATION_SYSTEM_GET_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const deleteHl7SystemConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7SystemConfigurationDeleteActions.request)),
    tap(() => {
      startDeleteHl7SystemConfigurationSpan();
    }),
    switchMap(() => {
      return from(deleteHl7SystemConfiguration$()).pipe(
        tap(() => {
          endDeleteHl7SystemConfigurationSpan();
          redirectWithQuery(integrationsRoutes.HL7_CONFIGURATION);
        }),
        map(() => hl7SystemConfigurationDeleteActions.success()),
        catchError(error => {
          endDeleteHl7SystemConfigurationSpan(error);
          return of(
            hl7SystemConfigurationDeleteActions.failure({
              code: getErrorMessageCode(
                ContextNames.HL7_CONFIGURATION,
                'hl7-configuration--system--delete-failed',
              ),
              message:
                'An error occurred while deleting the system configuration. Please try again.',
            }),
            showErrorBannerAction.request(
              errors.HL7_CONFIGURATION_SYSTEM_DELETE_FAILED(),
            ),
          );
        }),
      );
    }),
  );

export const saveHl7RuleConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7RuleConfigurationSaveActions.request)),
    tap(() => {
      startSaveHl7RuleConfigurationSpan();
    }),
    switchMap(({ payload }) => {
      return from(saveHl7RuleConfiguration$(payload)).pipe(
        tap(() => {
          endSaveHl7RuleConfigurationSpan();
          redirectWithQuery(integrationsRoutes.HL7_CONFIGURATION);
        }),
        map(() =>
          hl7RuleConfigurationSaveActions.success({
            displayName: payload.displayName,
            ruleType: payload.ruleType,
            ruleIdentifierMappings: payload.ruleIdentifierMappings,
          }),
        ),
        catchError(error => {
          endSaveHl7RuleConfigurationSpan(error);
          return of(
            hl7RuleConfigurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.HL7_CONFIGURATION,
                'hl7-configuration--rule--save-failed',
              ),
              message:
                'An error occurred while saving the rule configuration. Please try again.',
            }),
            showErrorBannerAction.request(errors.HL7_RULE_SAVE_FAILED()),
          );
        }),
      );
    }),
  );

export const getHl7RuleConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7RuleConfigurationGetActions.request)),
    tap(() => {
      startGetHl7RuleConfigurationsSpan();
    }),
    switchMap(() => {
      return from(getHl7RuleConfigurationsByTenant$()).pipe(
        tap(() => {
          endGetHl7RuleConfigurationsSpan();
        }),
        map(configuration =>
          hl7RuleConfigurationGetActions.success(configuration),
        ),
        catchError(error => {
          endGetHl7RuleConfigurationsSpan(error);
          return of(
            hl7RuleConfigurationSaveActions.failure({
              code: getErrorMessageCode(
                ContextNames.HL7_CONFIGURATION,
                'hl7-configuration--rule--get-failed',
              ),
              message:
                'An error occurred while retrieving the rule configuration. Please try again.',
            }),
            showErrorBannerAction.request(errors.HL7_RULE_GET_FAILED()),
          );
        }),
      );
    }),
  );

export const deleteHl7RuleConfigurationEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7RuleConfigurationDeleteActions.request)),
    tap(() => {
      startDeleteHl7RuleConfigurationSpan();
    }),
    switchMap(({ payload }) => {
      return from(deleteHl7RuleConfiguration$(payload)).pipe(
        tap(() => {
          endDeleteHl7RuleConfigurationSpan();
          window.location.reload();
        }),
        map(() => hl7RuleConfigurationDeleteActions.success()),
        catchError(error => {
          endDeleteHl7RuleConfigurationSpan(error);
          return of(
            hl7RuleConfigurationDeleteActions.failure({
              code: getErrorMessageCode(
                ContextNames.HL7_CONFIGURATION,
                'hl7-configuration--rule--delete-failed',
              ),
              message:
                'An error occurred while deleting the rule configuration. Please try again.',
            }),
            showErrorBannerAction.request(errors.HL7_RULE_DELETE_FAILED()),
          );
        }),
      );
    }),
  );

export const getHl7SuccessMessageCountEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7SuccessMessageCountActions.request)),
    tap(() => {
      startGetHl7SuccessMessageCountSpan();
    }),
    switchMap(({ payload }) => {
      return from(
        getHl7MessageCount$(payload, moment(), ['success']).pipe(
          tap(() => {
            endGetHl7SuccessMessageCountSpan();
          }),
          map(count => hl7SuccessMessageCountActions.success(count ?? 0)),
          catchError(error => {
            endGetHl7SuccessMessageCountSpan(error);
            return of(
              hl7SuccessMessageCountActions.failure({
                code: getErrorMessageCode(
                  ContextNames.HL7_CONFIGURATION,
                  'hl7-configuration--success-message-count--get-failed',
                ),
                message:
                  'An error occurred while retrieving the success message count. Please try again.',
              }),
              showErrorBannerAction.request(
                errors.HL7_SUCCESS_MESSAGE_COUNT_GET_FAILED(),
              ),
            );
          }),
        ),
      );
    }),
  );

export const getHl7ErroredMessageCountEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7ErroredMessageCountActions.request)),
    tap(() => {
      startGetHl7ErroredMessageCountSpan();
    }),
    switchMap(({ payload }) => {
      return from(
        getHl7MessageCount$(payload, moment(), ['error']).pipe(
          tap(() => {
            endGetHl7ErroredMessageCountSpan();
          }),
          map(count => hl7ErroredMessageCountActions.success(count ?? 0)),
          catchError(error => {
            endGetHl7ErroredMessageCountSpan(error);
            return of(
              hl7ErroredMessageCountActions.failure({
                code: getErrorMessageCode(
                  ContextNames.HL7_CONFIGURATION,
                  'hl7-configuration--errored-message-count--get-failed',
                ),
                message:
                  'An error occurred while retrieving the errored message count. Please try again.',
              }),
              showErrorBannerAction.request(
                errors.HL7_ERRORED_MESSAGE_COUNT_GET_FAILED(),
              ),
            );
          }),
        ),
      );
    }),
  );

export const getHl7UnsentMessageCountEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7UnsentMessageCountActions.request)),
    tap(() => {
      startGetHl7UnsentMessageCountSpan();
    }),
    switchMap(({ payload }) => {
      return from(
        getHl7MessageCount$(payload, moment(), ['unsent']).pipe(
          tap(() => {
            endGetHl7UnsentMessageCountSpan();
          }),
          map(count => hl7UnsentMessageCountActions.success(count ?? 0)),
          catchError(error => {
            endGetHl7UnsentMessageCountSpan(error);
            return of(
              hl7UnsentMessageCountActions.failure({
                code: getErrorMessageCode(
                  ContextNames.HL7_CONFIGURATION,
                  'hl7-configuration--unsent-message-count--get-failed',
                ),
                message:
                  'An error occurred while retrieving the unsent message count. Please try again.',
              }),
              showErrorBannerAction.request(
                errors.HL7_UNSENT_MESSAGE_COUNT_GET_FAILED(),
              ),
            );
          }),
        ),
      );
    }),
  );

export const searchHl7MessagesEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7MessageSearchActions.request)),
    tap(() => {
      startSearchHl7MessagesSpan();
    }),
    switchMap(({ payload }) => {
      return from(
        searchHl7Messages$(
          payload.startDate,
          payload.endDate,
          payload.messageStatuses,
          payload.pageNumber,
          payload.pageSize,
        ).pipe(
          tap(() => {
            endSearchHl7MessagesSpan();
          }),
          map(response => hl7MessageSearchActions.success(response)),
          catchError(error => {
            endSearchHl7MessagesSpan(error);
            return of(
              hl7MessageSearchActions.failure({
                code: getErrorMessageCode(
                  ContextNames.HL7_MESSAGES,
                  'hl7-messages--search--search-failed',
                ),
                message:
                  'An error occurred while searching for HL7 messages. Please try again.',
              }),
              showErrorBannerAction.request(
                errors.HL7_MESSAGES_SEARCH_FAILED(),
              ),
            );
          }),
          takeUntil(
            action$.pipe(
              filter(isActionOf(hl7MessageSearchActions.cancel)),
              tap(() => {
                cancelSearchHl7MessagesSpan();
              }),
            ),
          ),
        ),
      );
    }),
  );

export const getHl7MessageEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(hl7MessageActions.request)),
    tap(() => {
      startGetHl7MessageSpan();
    }),
    switchMap(({ payload }) => {
      return from(
        getHl7Message$(payload).pipe(
          tap(() => {
            endGetHl7MessageSpan();
          }),
          map(response => hl7MessageActions.success(response)),
          catchError(error => {
            endGetHl7MessageSpan(error);
            return of(
              hl7MessageActions.failure({
                id: payload,
                code: getErrorMessageCode(
                  ContextNames.HL7_MESSAGES,
                  'hl7-messages--get-failed',
                ),
                message:
                  'An error occurred while retrieving the HL7 message. Please try again.',
              }),
              showErrorBannerAction.request(errors.HL7_MESSAGE_GET_FAILED()),
            );
          }),
        ),
      );
    }),
  );

export const hl7ConfigurationEpic = combineEpics(
  saveHl7SystemConfigurationEpic,
  getHl7SystemConfigurationEpic,
  deleteHl7SystemConfigurationEpic,
  saveHl7RuleConfigurationEpic,
  getHl7RuleConfigurationEpic,
  deleteHl7RuleConfigurationEpic,
  getHl7ErroredMessageCountEpic,
  searchHl7MessagesEpic,
  getHl7MessageEpic,
  getHl7UnsentMessageCountEpic,
  getHl7SuccessMessageCountEpic,
);
