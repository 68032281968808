// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SaveFhirConfigurationRequest } from '@imprivata-cloud/adminapi-client';
import type { Dispatch } from 'react';
import { fhirConfigurationSaveActions } from './actions';

export const saveFhirConfiguration = (
  request: SaveFhirConfigurationRequest,
  dispatch: Dispatch<any>,
): void => {
  dispatch(fhirConfigurationSaveActions.request(request));
};
