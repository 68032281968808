// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Space, Spin, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import PageSubHeader from '../../../../components/page-sub-header/PageSubHeader';
import HelpButton from '../../../../components/action-bar/HelpButton';
import {
  useGetErroredHl7MessagesCount,
  useGetHl7RuleConfigurations,
  useGetHl7SystemConfiguration,
  useGetSuccessHl7MessagesCount,
  useGetUnsentHl7MessagesCount,
} from './store/hooks';
import classes from './Hl7ConfigurationContainer.module.less';
import { deleteHl7RuleConfiguration } from './store/facades';
import ConfirmActionModal from '../../../../components/confirm-modal/ConfirmActionModal';
import EmptyState from './components/empty-state/EmptyState';
import { redirectWithQuery } from '../../../../utils/routingHelpers';
import { integrationsRoutes } from '../../../../routers/route-names';
import SetTitle from '../../../../utils/DynamicTitleHelper';
import Hl7RuleConfigurationCardList from './components/hl7-rule-configuration-card-list/Hl7RuleConfigurationCardList';
import Hl7MessageCountCard from './components/hl7-message-count-card/Hl7MessageCountCard';

const { Text } = Typography;

export enum Hl7MessageType {
  Success = 'success',
  Unsent = 'unsent',
  Error = 'error',
}

const Hl7ConfigurationComponent: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  SetTitle(t('hl7-configuration.title'));

  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [ruleIdForDeletion, setRuleIdForDeletion] = useState<
    string | null | undefined
  >(null);

  const { systemConfiguration, isLoading: isSystemConfigLoading } =
    useGetHl7SystemConfiguration();
  const { ruleConfigurations, isLoading: isRuleConfigLoading } =
    useGetHl7RuleConfigurations();

  const {
    count: successMessagesCount,
    isLoading: isSuccessMessageCountLoading,
  } = useGetSuccessHl7MessagesCount(moment().subtract(7, 'days'));

  const {
    count: erroredMessagesCount,
    isLoading: isErroredMessageCountLoading,
  } = useGetErroredHl7MessagesCount(moment().subtract(7, 'days'));

  const { count: unsentMessagesCount, isLoading: isUnsentMessageCountLoading } =
    useGetUnsentHl7MessagesCount(moment().subtract(7, 'days'));

  const triggerDeleteModal = (ruleId: string | null | undefined) => {
    setRuleIdForDeletion(ruleId);
    setIsDeleteModalVisible(true);
  };

  const deleteHl7Rule = () => {
    if (ruleIdForDeletion) {
      deleteHl7RuleConfiguration(ruleIdForDeletion, dispatch);
    }
    resetDeleteModal();
  };

  const resetDeleteModal = () => {
    setRuleIdForDeletion(null);
    setIsDeleteModalVisible(false);
  };

  const redirectToSystemConfig = () => {
    redirectWithQuery(integrationsRoutes.HL7_SYSTEM_CONFIGURATION);
  };

  const redirectToRuleConfig = (ruleId: string | null | undefined = null) => {
    if (ruleId) {
      redirectWithQuery(integrationsRoutes.HL7_RULE_CONFIGURATION, [
        `ruleId=${ruleId}`,
      ]);
    } else {
      redirectWithQuery(integrationsRoutes.HL7_RULE_CONFIGURATION);
    }
  };

  const redirectToHl7Messages = (messageType: Hl7MessageType) => {
    const queryParameters = [
      `startDate=${moment().subtract(7, 'days').toISOString()}`,
      `status=${messageType}`,
    ];

    redirectWithQuery(integrationsRoutes.HL7_MESSAGES, queryParameters);
  };

  const showLoadingSpinner = () => {
    return (
      <div className={classes.spinner}>
        <Spin size="large" />
      </div>
    );
  };

  const showSystemConfiguration = () => {
    if (!systemConfiguration) {
      return showNoSystemConfigured();
    }

    return (
      <Space
        className={classes.hl7ConfigurationSpace}
        direction="vertical"
        size={16}
        style={{ display: 'flex' }}
      >
        <Row gutter={[24, 24]}>
          <Col lg={10} md={24} className={classes.countCardCol}>
            <Hl7MessageCountCard
              count={successMessagesCount}
              color="#018186"
              description="successfully sent"
              timeFrame="7 days"
              isLoading={isSuccessMessageCountLoading}
              onLinkClickAction={() =>
                redirectToHl7Messages(Hl7MessageType.Success)
              }
            ></Hl7MessageCountCard>
          </Col>
          <Col lg={7} md={24} className={classes.countCardCol}>
            <Hl7MessageCountCard
              count={erroredMessagesCount}
              color="#bb435a"
              description="errors"
              timeFrame="7 days"
              isLoading={isErroredMessageCountLoading}
              onLinkClickAction={() =>
                redirectToHl7Messages(Hl7MessageType.Error)
              }
            ></Hl7MessageCountCard>
          </Col>
          <Col lg={7} md={24} className={classes.countCardCol}>
            <Hl7MessageCountCard
              count={unsentMessagesCount}
              color="#C26A03"
              description="unsent"
              timeFrame="7 days"
              isLoading={isUnsentMessageCountLoading}
              onLinkClickAction={() =>
                redirectToHl7Messages(Hl7MessageType.Unsent)
              }
            ></Hl7MessageCountCard>
          </Col>
        </Row>
        <Row gutter={[24, 24]} className={classes.rowWithTopSpacing}>
          <Col lg={12} md={24}>
            <Row gutter={10}>
              <Col flex="auto">
                <div
                  data-testid="hl7-system-configuration--header"
                  className="h3-header"
                >
                  {t('hl7-configuration.system-configuration.title')}
                </div>
              </Col>
              <Col>
                <Button
                  data-testid="hl7-system-configuration--edit-system-btn"
                  variant={ButtonVariant.TEXT}
                  icon={<></>}
                  label={t(
                    'hl7-configuration.system-configuration.edit-system-btn',
                  )}
                  onClick={() => redirectToSystemConfig()}
                ></Button>
              </Col>
            </Row>
            <Row gutter={10} className={classes.rowWithTopSpacing}>
              <Col flex="auto">
                <Space direction="vertical" align="start">
                  <Text
                    data-testid="hl7-system-configuration--client-id"
                    className={classes.configurationText}
                  >
                    {t('hl7-configuration.system-configuration.client-id')}:{' '}
                    {systemConfiguration.clientId}
                  </Text>
                  <Text
                    data-testid="hl7-system-configuration--endpoint"
                    className={classes.configurationText}
                  >
                    {t('hl7-configuration.system-configuration.endpoint')}:{' '}
                    {systemConfiguration.endpoint}
                  </Text>
                  <Text
                    data-testid="hl7-system-configuration--audience"
                    className={classes.configurationText}
                  >
                    {t('hl7-configuration.system-configuration.audience')}:{' '}
                    {systemConfiguration.audience}
                  </Text>
                  <Text
                    data-testid="hl7-system-configuration--receiving-facility"
                    className={classes.configurationText}
                  >
                    {t(
                      'hl7-configuration.system-configuration.receiving-facility',
                    )}
                    : {systemConfiguration.receivingFacility}
                  </Text>
                  <Text
                    data-testid="hl7-system-configuration--receiving-application"
                    className={classes.configurationText}
                  >
                    {t(
                      'hl7-configuration.system-configuration.receiving-application',
                    )}
                    : {systemConfiguration.receivingApplication}
                  </Text>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col lg={12} md={24}>
            <Row gutter={10}>
              <Col flex="auto">
                <div
                  data-testid="hl7-rule-configuration--header"
                  className="h3-header"
                >
                  {t('hl7-configuration.rule-configuration.title')}
                </div>
              </Col>
              <Col>
                {ruleConfigurations ? (
                  <Button
                    data-testid="hl7-rule-configuration--add-rule-btn"
                    variant={ButtonVariant.TEXT}
                    label={t('hl7-configuration.rule-configuration.add-rule')}
                    icon={<PlusOutlined />}
                    onClick={() => redirectToRuleConfig()}
                    ghost
                  ></Button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
            <Row gutter={10} className={classes.rowWithTopSpacing}>
              <Col flex="auto">
                {isRuleConfigLoading ? showLoadingSpinner() : showRules()}
              </Col>
            </Row>
          </Col>
        </Row>
      </Space>
    );
  };

  const showNoSystemConfigured = () => {
    return (
      <EmptyState
        content={t('hl7-configuration.system-configuration.no-system')}
        actionText={t(
          'hl7-configuration.system-configuration.add-system-title',
        )}
        onActionClick={() => redirectToSystemConfig()}
      />
    );
  };

  const showNoRulesConfigured = () => {
    return (
      <EmptyState
        content={t('hl7-configuration.rule-configuration.no-rules')}
        actionText={t('hl7-configuration.rule-configuration.add-rule-title')}
        onActionClick={() => redirectToRuleConfig()}
      />
    );
  };

  const showRules = () => {
    if (!ruleConfigurations || ruleConfigurations.length <= 0) {
      return showNoRulesConfigured();
    }

    return (
      <Col flex="auto">
        <Hl7RuleConfigurationCardList
          configurations={ruleConfigurations ?? []}
          onConfigureAction={redirectToRuleConfig}
          onDeleteAction={triggerDeleteModal}
        />
      </Col>
    );
  };

  return (
    <>
      <PageSubHeader
        title={
          <>
            <ConfirmActionModal
              title={t(
                'hl7-configuration.rule-configuration.delete-rule-modal.title',
              )}
              cancelText={t(
                'hl7-configuration.rule-configuration.delete-rule-modal.cancel-text',
              )}
              okText={t(
                'hl7-configuration.rule-configuration.delete-rule-modal.affirm-text',
              )}
              content={t(
                'hl7-configuration.rule-configuration.delete-rule-modal.content',
              )}
              visible={isDeleteModalVisible}
              onSave={() => deleteHl7Rule()}
              onCancel={() => resetDeleteModal()}
              onClose={() => resetDeleteModal()}
            ></ConfirmActionModal>
          </>
        }
        extra={
          <>
            <HelpButton />
          </>
        }
      />
      {isSystemConfigLoading ? showLoadingSpinner() : showSystemConfiguration()}
    </>
  );
};

export default Hl7ConfigurationComponent;
