// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Layout, { Content } from 'antd/lib/layout/layout';
import { Col, Row, Image, Skeleton } from 'antd';
import { useEffect, useState } from 'react';
import type { PatientDecrypted } from '../../api/types';
import { PATIENT_SEARCH_ROUTE } from '../../routers/route-names';
import classes from './PatientDetailsContainer.module.less';
import PageLayout from '../../components/page-layout/PageLayout';
import NoPhotoSrc from '../../assets/svg/no-photo.svg';
import PatientDemographics from './components/patient-demographics/PatientDemographics';
import { usePatientSearch } from '../patient-search/store/hooks';
import { patientDelete } from './store/facades';
import {
  getPathWithQuery,
  redirectWithQuery,
} from '../../utils/routingHelpers';
import ConfirmActionModal from '../../components/confirm-modal/ConfirmActionModal';
import { getPatient$ } from '../../api/services/patientService';
import { endGetPatientSpan, startGetPatientSpan } from './tracing';
import { clearPatientSearchAction } from '../patient-search/store/actions';
import SkeletonLine from '../../components/skeleton-line/SkeletonLine';
import { showErrorBannerAction } from '../../store/error-banner-state/actions';
import { errors } from '../../store/error-banner-state/errors';

const PatientDetailsContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const currentSearchParams = new URLSearchParams(location.search);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { selectedPatient } = usePatientSearch();
  const [patient, setPatient] = useState<PatientDecrypted | null>(
    selectedPatient,
  );
  const [isLoading, setIsLoading] = useState(false);
  const localeDateString = (patient: PatientDecrypted | null) => {
    return patient ? moment(patient.createdTimestamp).format('YYYY-MM-DD') : '';
  };

  const toDataUri = (patient: PatientDecrypted | null) => {
    return patient ? 'data:image/jpeg;base64,' + patient.photo : '';
  };

  const onDeleteButtonClick = () => {
    setIsModalVisible(true);
  };

  const onCancelClick = () => {
    setIsModalVisible(false);
  };

  const performPatientDeletion = (patient: PatientDecrypted | null) => {
    patientDelete(patient?.id ? patient.id : '', dispatch);
    redirectWithQuery(PATIENT_SEARCH_ROUTE);
  };

  const useGetPatientEffect = () =>
    useEffect(() => {
      if (!patient) {
        const patientId = currentSearchParams.get('patient');
        if (patientId) {
          setIsLoading(true);
          startGetPatientSpan();
          getPatient$(patientId)
            .toPromise()
            .then(resultPatient => {
              endGetPatientSpan();
              setPatient(resultPatient);
              setIsLoading(false);
            })
            .catch(err => {
              endGetPatientSpan(err);
              setIsLoading(false);
              if (err.status === 404) {
                dispatch(
                  showErrorBannerAction.request(
                    errors.PATIENT_DETAILS_GET_FAILED(),
                  ),
                );
                redirectWithQuery(PATIENT_SEARCH_ROUTE);
              }
            });
        } else {
          dispatch(
            showErrorBannerAction.request(errors.PATIENT_DETAILS_GET_FAILED()),
          );
          redirectWithQuery(PATIENT_SEARCH_ROUTE);
        }
      }
    }, []);
  useGetPatientEffect();

  useEffect(() => {
    return () => {
      const nextPathname = history.location.pathname;
      if (nextPathname !== PATIENT_SEARCH_ROUTE) {
        dispatch(clearPatientSearchAction());
      }
    };
  }, [history, dispatch]);

  return (
    <PageLayout title={t('navigation.patient-details')}>
      <Layout>
        <Content style={{ padding: '0.625rem' }}>
          <Row>
            <Link
              data-testid="patient-search-link"
              to={getPathWithQuery(PATIENT_SEARCH_ROUTE)}
            >
              {t('patient-details.back-to-search')}
            </Link>
          </Row>
          <Row style={{ marginTop: '1.5rem' }}>
            <Col span={4}>
              <Row
                className={`${classes.imageRow} ${
                  isLoading ? classes.imageRowLoading : ''
                }`}
              >
                {isLoading ? (
                  <Skeleton.Image
                    active={true}
                    className={classes.patientPhotoSkeleton}
                  />
                ) : (
                  <Image
                    data-testid="patient-detail--photo"
                    className={classes.patientPhoto}
                    src={toDataUri(patient)}
                    fallback={NoPhotoSrc}
                  ></Image>
                )}
              </Row>
              <Row>
                <Col style={{ marginTop: '0.5rem' }}>
                  {isLoading ? (
                    <SkeletonLine style={{ width: '16rem' }} />
                  ) : (
                    localeDateString(patient)
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={20} style={{ paddingLeft: '1.875rem' }}>
              <PatientDemographics
                onDeleteButtonClick={() => onDeleteButtonClick()}
                patient={patient}
                isLoading={isLoading}
              ></PatientDemographics>
            </Col>
          </Row>
          <ConfirmActionModal
            title={t('patient-details.delete-patient-modal.title')}
            cancelText={t(
              'patient-details.delete-patient-modal.cancel-button-text',
            )}
            okText={t(
              'patient-details.delete-patient-modal.confirm-button-text',
            )}
            content={t('patient-details.delete-patient-modal.content')}
            visible={isModalVisible}
            onSave={() => performPatientDeletion(patient)}
            onCancel={() => onCancelClick()}
            onClose={() => onCancelClick()}
          ></ConfirmActionModal>
        </Content>
      </Layout>
    </PageLayout>
  );
};

export default PatientDetailsContainer;
