// Copyright 2022, Imprivata, Inc.  All rights reserved.

import i18n, { type InitOptions } from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocalResourcesBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translations from './translations/en.json';

export enum ContextNames {
  DASHBOARD = 'dashboard',
  LOGIN = 'login',
  FHIR_CONFIGURATION = 'fhir-configuration',
  HL7_CONFIGURATION = 'hl7-configuration',
  HL7_MESSAGES = 'hl7-messages',
  HL7_CERTIFICATE = 'hl7-certificate',
  PATIENT_SEARCH = 'patient-search',
  INSTALLER = 'installer',
  PATIENT_DELETION = 'patient-deletion',
  REPORTS = 'reports',
}

export const defaultNS = 'translation' as const;

export const ns = [defaultNS];

export const resources = {
  en: {
    [defaultNS]: translations,
  },
} as const;

const i18nInstance = i18n.createInstance({
  debug: false,
  defaultNS,
  ns,
  lng: 'en',
  fallbackLng: 'en',
  returnEmptyString: false,
  interpolation: { escapeValue: false },
  backend: {
    backends: [
      // Load translations from json files in ./translations
      LocalResourcesBackend(resources),
    ],
  },

  detection: {
    order: ['querystring', 'navigator'],
    lookupQuerystring: 'lng',
  },

  react: {
    useSuspense: true,
  },
} as InitOptions);

void i18nInstance.use(ChainedBackend).use(LanguageDetector).init();

export * from './localeProvider';

export { i18nInstance as i18n };
export default i18nInstance;
