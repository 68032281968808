// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createAction, createAsyncAction } from 'typesafe-actions';
import type { ApiError } from '../../../api/types';

export const patientDeleteActions = createAsyncAction(
  'patient/details/PATIENT_DELETE_REQUEST',
  'patient/details/PATIENT_DELETE__SUCCESS',
  'patient/details/PATIENT_DELETE__FAILURE',
  'patient/details/PATIENT_DELETE__CANCEL',
)<string, string, ApiError, string | void>();

export const clearPatientDeleteAction = createAction(
  'patient/details/CLEAR_PATIENT_DELETE',
)<void>();
