// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, DatePicker, Divider, Form, Row, Typography } from 'antd';
import moment from 'moment-timezone';
import { useDispatch } from 'react-redux';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useLocation } from 'react-router-dom';
import type { RangePickerProps } from 'antd/es/date-picker';
import { runReport } from '../../store/facades';
import { useRunReport } from '../../store/hooks';
import { ReportTypeEnum } from '../../../../api/types';
import reportsClasses from '../../Reports.module.less';
import { replaceQueryParameters } from '../../../../utils/routingHelpers';

const AuditReportComponent: React.FC = () => {
  const { t } = useTranslation();
  const [auditExtractForm] = Form.useForm();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentSearchParams = new URLSearchParams(location.search);
  const [isRunReportDisabled, setRunReportDisabled] = useState<boolean>(true);
  const { isProcessing } = useRunReport();

  const onRangeChange: RangePickerProps['onChange'] = async (
    dates,
    dateStrings,
  ) => {
    setRunReportDisabled(false);
    if (dates) {
      auditExtractForm.setFieldsValue({
        dateRange: dates,
      });

      if (await isFormInvalid()) {
        setRunReportDisabled(true);
      } else {
        replaceQueryParameters([
          `startDate=${dates[0]?.format('YYYYMMDD')}`,
          `endDate=${dates[1]?.format('YYYYMMDD')}`,
        ]);
      }
    }
  };

  const isFormInvalid = async () => {
    try {
      await auditExtractForm.validateFields();
    } catch (err) {
      return true;
    }

    return false;
  };

  const validateAndRunReport = async () => {
    const dateRange: moment.Moment[] =
      auditExtractForm.getFieldValue('dateRange') || [];
    const startDate: moment.Moment | null = dateRange && dateRange[0];
    const endDate: moment.Moment | null = dateRange && dateRange[1];

    auditExtractForm.setFieldsValue({
      dateRange: dateRange,
    });

    if ((await isFormInvalid()) || !startDate) {
      return;
    }

    // if end date is TODAY, get the current time so that new reports are up to date
    const currentEndDate = endDate.isSame(new Date(), 'day')
      ? moment()
      : endDate.endOf('day');

    runReport(
      ReportTypeEnum.AuditExtract,
      startDate.startOf('day').add(1, 'millisecond'),
      currentEndDate,
      moment.tz.guess(true),
      dispatch,
    );
  };

  const isValidDate = (date: moment.Moment | null) => {
    return date !== null && date <= moment();
  };

  const disabledDate: RangePickerProps['disabledDate'] = current => {
    // Can not select days in future
    return !isValidDate(current);
  };

  const useFetchDatesEffect = () =>
    useEffect(() => {
      let start: moment.Moment | null = null;
      let end: moment.Moment | null = null;

      const startDateString = currentSearchParams.get('startDate');
      if (startDateString) {
        start = moment(startDateString);
      }

      const endDateString = currentSearchParams.get('endDate');
      if (endDateString) {
        end = moment(endDateString);
      }

      if (start && end && isValidDate(start) && isValidDate(end)) {
        // make sure start is a date before end
        if (end.isBefore(start)) {
          const swap = start;
          start = end;
          end = swap;

          replaceQueryParameters([
            `startDate=${start.format('YYYYMMDD')}`,
            `endDate=${end.format('YYYYMMDD')}`,
          ]);
        }

        auditExtractForm.setFieldsValue({
          dateRange: [start, end],
        });
        setRunReportDisabled(false);
      } else {
        replaceQueryParameters();
      }
    }, []);

  useFetchDatesEffect();

  return (
    <>
      <Row>
        <Card>
          <Typography>
            <Title>{t('reports.audit-extract.title')}</Title>
            <Paragraph className={reportsClasses.reportDescription}>
              {t('reports.audit-extract.description')}
            </Paragraph>
          </Typography>
          <Divider />
          <Form
            form={auditExtractForm}
            layout="vertical"
            name="auditExtractForm"
            requiredMark={false}
          >
            <Form.Item
              data-testid="reports--date-range-label"
              label={t('reports.parameters.date-range')}
              name="dateRange"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <DatePicker.RangePicker
                data-testid="reports--date-range-value-input"
                size="large"
                name="dateRangeValue"
                disabledDate={disabledDate}
                ranges={{
                  'Last 7 Days': [moment().add(-7, 'd'), moment()],
                  'Last 14 Days': [moment().add(-14, 'd'), moment()],
                  'Last 30 Days': [moment().add(-30, 'd'), moment()],
                  'Last 90 Days': [moment().add(-90, 'd'), moment()],
                }}
                onChange={onRangeChange}
              />
            </Form.Item>
          </Form>
          <Divider />
          <Button
            data-testid="reports--run-report-button"
            type="primary"
            htmlType="submit"
            style={{ width: '100%' }}
            onClick={async () => validateAndRunReport()}
            disabled={isRunReportDisabled}
          >
            {isProcessing ? 'Processing' : 'Download Report'}
          </Button>
        </Card>
      </Row>
    </>
  );
};

export default AuditReportComponent;
