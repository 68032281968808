// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { TenantType } from '@imprivata-cloud/adminapi-client';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const initialStateTestAction = createAsyncAction(
  'initial-state-test/REQUEST',
  'initial-state-test/SUCCESS',
  'initial-state-test/FAILURE',
)<void, void, void>();

export const stateSaveUsernameAction = createAction(
  'state-save-username',
)<string>();

export const logoutAction = createAsyncAction(
  'logout/REQUEST',
  'logout/SUCCESS',
  'logout/FAILURE',
)<void, void, void>();

export const invalidSessionAction = createAsyncAction(
  'invalid-session/REQUEST',
  'invalid-session/SUCCESS',
  'invalid-session/FAILURE',
)<
  // The params will be used to fill the reset state
  { storedURL?: string; errorMsgKey: string },
  { storedURL?: string; errorMsgKey: string },
  void
>();

export const getTenantTypeAction = createAsyncAction(
  'get-tenant-type/REQUEST',
  'get-tenant-type/SUCCESS',
  'get-tenant-type/FAILURE',
)<void, TenantType, void>();

export const startClientUserSessionAction = createAsyncAction(
  'start-client-user-session/REQUEST',
  'start-client-user-session/SUCCESS',
  'start-client-user-session/FAILURE',
)<{ tenantId: string }, boolean, string>();
