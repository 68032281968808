// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useSelector } from 'react-redux';
import {
  isReportProcessingSelector,
  runReportErrorSelector,
} from './selectors';

export const useRunReport = (): {
  isProcessing: ReturnType<typeof isReportProcessingSelector>;
  error: ReturnType<typeof runReportErrorSelector>;
} => {
  const isProcessing = useSelector(isReportProcessingSelector);
  const error = useSelector(runReportErrorSelector);
  return {
    isProcessing,
    error,
  };
};
