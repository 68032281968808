// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic, StateObservable } from 'redux-observable';
import type { RootState } from '../rootReducer';
import { showErrorBannerAction } from './actions';

export const createErrorBannerEpic: Epic = (
  action$,
  state$: StateObservable<RootState>,
) => {
  return action$.pipe(
    filter(isActionOf(showErrorBannerAction.request)),
    switchMap(({ payload }) => {
      if (
        !state$.value.errorBanner.errors.some(
          error =>
            error.message === payload.message &&
            error?.description === payload?.description,
        )
      ) {
        return of(showErrorBannerAction.success(payload));
      }
      return of();
    }),
  );
};
