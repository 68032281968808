// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import type { ApiError } from '../../../api/types';
import type { RootAction } from '../../../store/rootAction';
import type { RootState } from '../../../store/rootReducer';
import { getDashboardTrendData$ } from '../../../api/services/dashboardService';
import { trendDataChartActions } from './actions';
import {
  cancelGetDashboardTrendDataSpan,
  endGetDashboardTrendDataSpan,
  startGetDashboardTrendDataSpan,
} from '../tracing';
import { showErrorBannerAction } from '../../../store/error-banner-state/actions';
import { errors } from '../../../store/error-banner-state/errors';
import { getErrorMessageCode } from '../../../i18n/utils';
import { ContextNames } from '../../../i18n/config';

export const getTrendDataChartDataEpic: Epic<
  RootAction,
  RootAction,
  RootState
> = action$ =>
  action$.pipe(
    filter(isActionOf(trendDataChartActions.request)),
    tap(() => {
      startGetDashboardTrendDataSpan();
    }),
    switchMap(action =>
      getDashboardTrendData$(action.payload).pipe(
        tap(() => {
          endGetDashboardTrendDataSpan();
        }),
        map(res => {
          return trendDataChartActions.success(res);
        }),
        catchError((err: ApiError) => {
          endGetDashboardTrendDataSpan(err);
          return of(
            trendDataChartActions.failure({
              code: getErrorMessageCode(
                ContextNames.DASHBOARD,
                'load-dashboard-failed',
              ),
              message:
                'An error occurred while loading the dashboard. Please try again.',
            }),
            showErrorBannerAction.request(errors.DASHBOARD_LOAD_FAILED()),
          );
        }),
        takeUntil(
          action$.pipe(
            filter(isActionOf(trendDataChartActions.cancel)),
            tap(() => {
              cancelGetDashboardTrendDataSpan();
            }),
          ),
        ),
      ),
    ),
  );

export const dashboardEpic = combineEpics(getTrendDataChartDataEpic);
