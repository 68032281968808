// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Switch } from 'react-router-dom';
import ProtectedRoute from '../../components/protected-route/ProtectedRoute';
import { reportsRoutes } from '../../routers/route-names';
import ActivityExtractComponent from './containers/activity-extract/ActivityReportContainer';
import AuditReportComponent from './containers/audit-extract/AuditReportContainer';

const ReportsRoutes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={reportsRoutes.ACTIVITY_EXTRACT}
        component={ActivityExtractComponent}
      />
      <ProtectedRoute
        path={reportsRoutes.AUDIT_EXTRACT}
        component={AuditReportComponent}
      />
    </Switch>
  );
};

export default ReportsRoutes;
