// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { _getEnvVariable, _getUrls } from '@imprivata-cloud/common';

declare global {
  interface Window {
    _appConfig: Record<string, string>;
  }
}

export type AppEnvConfig = {
  PA_API_URL: string;
  OTLP_TRACING_URL?: string;
  METADATA_URL: string;
};

export interface ApiUrls {
  paApiUrl: string;
  metadataUrl: string;
}

let envConfig: AppEnvConfig;

function _getPaApiUrl(): ApiUrls {
  const url = window.location.host;
  const urlRegex = /\w+\.\w+\.(\w+)\.\w+\.(\w+)/gim;
  const matches = urlRegex.exec(url);

  let paApiUrl = '';
  let metadataUrl = '';

  if (Array.isArray(matches) && matches.length > 0) {
    matches.shift();
    const [appEnv, tld] = matches;

    paApiUrl = `https://ps.sys.${appEnv}.imprivata.${tld}/psadminui`;
    metadataUrl = `https://metadata.app.${appEnv}.imprivata.${tld}/data-privacy/public.json`;
  } else if (!url?.includes('localhost')) {
    // prod environment
    paApiUrl = 'https://ps.sys.prod.imprivata.com/psadminui';
    metadataUrl = 'https://metadata.app.imprivata.com/data-privacy/public.json';
  }

  return { paApiUrl, metadataUrl };
}

export function _initAppConfig(
  window: Window,
  env: ImportMetaEnv,
): AppEnvConfig {
  const { tracingUrl } = _getUrls();
  const { paApiUrl, metadataUrl } = _getPaApiUrl();

  // file deepcode ignore MissingArgument
  return {
    PA_API_URL:
      (_getEnvVariable(window, env, 'PA_API_URL', paApiUrl) as string) ??
      paApiUrl,
    OTLP_TRACING_URL: _getEnvVariable(
      window,
      env,
      'OTLP_TRACING_URL',
      tracingUrl,
    ),
    METADATA_URL:
      (_getEnvVariable(window, env, 'METADATA_URL', metadataUrl) as string) ??
      metadataUrl,
  };
}

export default function getConfig(): AppEnvConfig {
  if (!envConfig) {
    envConfig = _initAppConfig(window, import.meta.env);
  }
  return envConfig;
}
