// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Radio, Row, Spin } from 'antd';
import type { FhirConfiguration } from '@imprivata-cloud/adminapi-client';
import { InputBox } from '@imprivata-cloud/components';
import { useDispatch } from 'react-redux';
import PageSubHeader from '../../../../components/page-sub-header/PageSubHeader';
import CancelButton from '../../../../components/action-bar/CancelButton';
import HelpButton from '../../../../components/action-bar/HelpButton';
import SaveButton from '../../../../components/action-bar/SaveButton';
import SaveDiscardModal from '../../../../components/save-discard-modal/SaveDiscardModal';
import { useGetFhirConfiguration } from './store/hooks';
import classes from './FhirConfigurationContainer.module.less';
import { saveFhirConfiguration } from './store/facades';
import SetTitle from '../../../../utils/DynamicTitleHelper';

let isDirty: boolean;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const requiredMsgKey = 'fhir-configuration.required';

const setConfigurationFormData = (formData: FhirConfiguration | null) => {
  return {
    audienceLabel: formData?.audience,
    clientIdLabel: formData?.clientId,
    issuerLabel: formData?.issuer,
    identifierSystemLabel: formData?.identifierSystem,
    typeLabel: formData?.type,
    epicRestApiBaseAddressLabel: formData?.epicRestApiBaseAddress,
  };
};

const FhirConfigurationComponent: React.FC = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  SetTitle(t('fhir-configuration.title'));

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [reset, setReset] = useState(false);

  const { configuration, isLoading } = useGetFhirConfiguration();

  const validateAndUpdate = () => {
    isDirty = false;

    const audienceValue = form.getFieldValue('audienceLabel') || '';
    const clientIdValue = form.getFieldValue('clientIdLabel') || '';
    const issuerValue = form.getFieldValue('issuerLabel') || '';
    const identifierSystemValue =
      form.getFieldValue('identifierSystemLabel') || '';
    const typeValue = form.getFieldValue('typeLabel') || 0;
    const epicRestApiBaseAddressValue =
      form.getFieldValue('epicRestApiBaseAddressLabel') || '';

    form.setFieldsValue({
      audienceLabel: audienceValue,
      clientIdLabel: clientIdValue,
      issuerLabel: issuerValue,
      identifierSystemLabel: identifierSystemValue,
      typeLabel: typeValue,
      epicRestApiBaseAddressLabel: epicRestApiBaseAddressValue,
    });

    form.validateFields().then(() => {
      saveFhirConfiguration(
        {
          audience: audienceValue,
          clientId: clientIdValue,
          issuer: issuerValue,
          identifierSystem: identifierSystemValue,
          type: typeValue,
          epicRestApiBaseAddress: epicRestApiBaseAddressValue,
        },
        dispatch,
      );

      setIsSaveDisabled(true);
      setIsModalVisible(false);
    });
  };

  const resetForm = () => {
    if (isDirty) {
      form.setFieldsValue(setConfigurationFormData(configuration));
      setReset(!reset);
    }

    setIsSaveDisabled(true);
    isDirty = false;
    setIsModalVisible(false);
  };

  const handleChange = () => {
    setIsSaveDisabled(false);

    form.validateFields().catch(() => {
      setIsSaveDisabled(true);
    });

    isDirty = true;
  };

  useEffect(() => {
    return () => {
      if (isDirty) {
        setIsModalVisible(true);
      }
    };
  });

  useEffect(() => {
    form.setFieldsValue(setConfigurationFormData(configuration));
  }, [configuration, form]);

  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <PageSubHeader
        title={
          <>
            <SaveButton
              onClick={() => validateAndUpdate()}
              disabled={isSaveDisabled}
            />
            <CancelButton onClick={() => resetForm()} disabled={!isDirty} />
            <SaveDiscardModal
              title={t('fhir-configuration.save-discard-modal.title')}
              cancelText={t('fhir-configuration.save-discard-modal.discard')}
              okText={t('actions.save')}
              content={t('fhir-configuration.save-discard-modal.content')}
              open={isModalVisible}
              onSave={() => validateAndUpdate()}
              onDiscard={() => resetForm()}
            ></SaveDiscardModal>
          </>
        }
        extra={<HelpButton />}
      />
      <Form
        {...formItemLayout}
        form={form}
        layout="vertical"
        name="register"
        onChange={handleChange}
        className={classes.fhirConfigurationForm}
      >
        <div>
          <Row gutter={10} wrap={true} className={classes.rowWithTopSpacing}>
            <Col>
              <div
                data-testid="fhir-configuration-form--header"
                className="h3-header"
              >
                {t('fhir-configuration.title')}
              </div>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="fhir-configuration-form--audience-label"
                name="audienceLabel"
                label={t('fhir-configuration.audience')}
                rules={[
                  {
                    required: true,
                    message: t(requiredMsgKey),
                  },
                ]}
              >
                <InputBox
                  data-testid="fhir-configuration--audience"
                  className={classes.input}
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="fhir-configuration-form--client-id-label"
                name="clientIdLabel"
                label={t('fhir-configuration.client-id')}
                rules={[
                  {
                    required: true,
                    message: t(requiredMsgKey),
                  },
                ]}
              >
                <InputBox
                  data-testid="fhir-configuration--client-id"
                  className={classes.input}
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="fhir-configuration-form--issuer-label"
                name="issuerLabel"
                label={t('fhir-configuration.issuer')}
                rules={[
                  {
                    required: true,
                    message: t(requiredMsgKey),
                  },
                ]}
              >
                <InputBox
                  data-testid="fhir-configuration--issuer"
                  className={classes.input}
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="fhir-configuration-form--identifier-system-label"
                name="identifierSystemLabel"
                label={t('fhir-configuration.identifier-system')}
                rules={[
                  {
                    required: true,
                    message: t(requiredMsgKey),
                  },
                ]}
              >
                <InputBox
                  data-testid="fhir-configuration--identifier-system"
                  placeholder="urn:oid:1.1.111"
                  className={classes.input}
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="fhir-configuration-form--epic-rest-api-base-address-label"
                name="epicRestApiBaseAddressLabel"
                label={t('fhir-configuration.epic-rest-api-base-address')}
              >
                <InputBox
                  data-testid="fhir-configuration--epic-rest-api-base-address"
                  placeholder="https://apporchard.epic.com/interconnect-aocurprd-username"
                  className={classes.input}
                  size="small"
                  type="text"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <Form.Item
                data-testid="fhir-configuration-form--type-label"
                name="typeLabel"
                label={t('fhir-configuration.type')}
              >
                <Radio.Group size="small">
                  <Radio
                    data-testid="fhir-configuration--type-generic"
                    value={0}
                  >
                    Generic
                  </Radio>
                  <Radio data-testid="fhir-configuration--type-epic" value={1}>
                    Epic
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col flex="1"></Col>
          </Row>
        </div>
      </Form>
    </>
  );
};

export default FhirConfigurationComponent;
