// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import type { ActivityType } from '@imprivata-cloud/adminapi-client';
import type { MappedChartDatum } from '../../../api/types';
import type { Moment } from 'moment-timezone';
import type { RootState } from '../../../store/rootReducer';
import type { DashboardTrendDataRequest } from '../types';
import { trendDataChartActions } from './actions';

export const trendDataChartState = (state: RootState): MappedChartDatum[] => {
  return state.dashboard.trendData.mappedChartData;
};

export const trendDataChartTotalsState = (
  state: RootState,
): {
  [key in keyof typeof ActivityType]?: number;
} => {
  return state.dashboard.trendData.chartDataTotals;
};

export const isLoadingState = (state: RootState): boolean => {
  return state.dashboard.trendData.loading;
};

export const latestActivityDateTimeState = (
  state: RootState,
): Moment | null => {
  return state.dashboard.trendData.latestActivityDateTime;
};

export function useGetMappedTrendDataChartData(
  request: DashboardTrendDataRequest,
): {
  mappedChartData: MappedChartDatum[];
  chartTotals: {
    [key in keyof typeof ActivityType]?: number;
  };
  isLoading: boolean;
  latestActivityDateTime: Moment | null;
} {
  const dispatch = useDispatch();
  const mappedChartData = useSelector(trendDataChartState);
  const chartTotals = useSelector(trendDataChartTotalsState);
  const isLoading = useSelector(isLoadingState);
  const latestActivityDateTime = useSelector(latestActivityDateTimeState);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(trendDataChartActions.request(request));

      return () => {
        dispatch(trendDataChartActions.cancel());
      };
    }, []);
  useMountEffect();

  return { mappedChartData, chartTotals, isLoading, latestActivityDateTime };
}
