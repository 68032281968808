// Copyright 2022, Imprivata, Inc.  All rights reserved.
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { ActivityType, ActivitySubtype, TimePeriodType} from '@imprivata-cloud/adminapi-client';
import { Column } from '@ant-design/plots';
import clsx from 'clsx';
import moment from 'moment-timezone';
import type { ColumnConfig } from '@ant-design/plots';
import type { MappedChartDatum } from '../../../../api/types';
import { useGetMappedTrendDataChartData } from '../../store/hooks';
import classes from './DashboardView.module.less';
import DashboardCard from '../dashboard-card/DashboardCard';

const INTIAL_TIME_PERIOD_TYPE: TimePeriodType = TimePeriodType.Month;

const DashboardView: React.FC = () => {
  const { t } = useTranslation();
  const dashboardTotalsTranslations = t(['dashboard.totals'], {
    returnObjects: true,
  });

  const [timePeriodType] = useState<TimePeriodType>(INTIAL_TIME_PERIOD_TYPE);

  const timeZoneId = moment.tz.guess(true);
  const { mappedChartData, chartTotals, isLoading, latestActivityDateTime } =
    useGetMappedTrendDataChartData({
      startDate: moment()
        .subtract(12, 'months')
        .set({ date: 1, hour: 0, minute: 0, second: 0, millisecond: 0 }),
      endDate: moment(),
      timePeriodType: timePeriodType,
      activityTypes: [
        ActivityType.Identification,
        ActivityType.Verification,
        ActivityType.Enrollment,
      ],
      activitySubtypes: [
        ActivitySubtype.Success,
        ActivitySubtype.MultipleMatchesSuccess
      ],
      timeZoneId,
    });

  const mappedChartDataRef = React.useRef<MappedChartDatum[] | undefined>();

  React.useEffect(() => {
    if (mappedChartData) {
      mappedChartDataRef.current = mappedChartData;
    }
  }, [mappedChartData]);

  const columnConfig: ColumnConfig = {
    data: mappedChartData,
    isStack: true,
    xField: 'label',
    yField: 'count',
    seriesField: 'activityType',
    color: ['#FFA600', '#BC5090', '#003F5C'],
    legend: {
      layout: 'horizontal',
      position: 'top-right',
      reversed: true
    } as const,
    style: {
      height: '100%',
      width: '100%',
      background: '#fff',
    },
    maxColumnWidth: 30,
  };

  return (
    <>
      {isLoading && !mappedChartData ? (
        <Spin
          data-testid="dashboard-view-container"
          className={classes.spinner}
          spinning
        />
      ) : (
        <>
          <div
            className={classes.latestActivityDateTime}
            data-testid="dashboard-latest-activity-date-time"
          >
            {latestActivityDateTime
              ? `${t('dashboard.latest-activity-date-time')} ${moment(
                  latestActivityDateTime,
                ).format('YYYY-MM-DD HH:mm:ss')}`
              : ''}
          </div>
          <div
            className={classes.container}
            data-testid="dashboard-view-container"
          >
            <div
              className={clsx('elevation-astra-1', classes.dashboardTop)}
              data-testid="column-chart-panel"
            >
              <div className={clsx(classes.columnChartWrapper, 'p-5')}>
                <span className="subtitle">
                  {t('dashboard.chart.monthly-header')}
                </span>
                <div className={classes.columnChart}>
                  <Column {...columnConfig} loading={!!isLoading} />
                </div>
              </div>
              <div className={classes.dashboardCards}>
                {Object.entries(chartTotals).map(entry => {
                  const activityType = entry[0].toLowerCase();
                  return (
                    <React.Fragment key={`${activityType}_dashboard_card`}>
                      <DashboardCard
                        data={(entry[1] ?? 0).toString()}
                        label={
                          dashboardTotalsTranslations[
                            activityType as keyof typeof dashboardTotalsTranslations
                          ]
                        }
                      />
                    </React.Fragment>
                  );
                })}
                <DashboardCard data={' '} label={''} />
                <DashboardCard data={' '} label={''} />
                <DashboardCard data={' '} label={''} />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DashboardView;
