// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  tracer,
  SpanNames,
  recordException,
  startRootSpan,
  endRootSpan,
} from '../../tracing';

export function startSaveFhirConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.SAVE_FHIR_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endSaveFhirConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.SAVE_FHIR_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.SAVE_FHIR_CONFIGURATION);
  endRootSpan();
}

export function startGetFhirConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_FHIR_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetFhirConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_FHIR_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_FHIR_CONFIGURATION);
  endRootSpan();
}

export function startSaveHl7SystemConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.SAVE_HL7_SYSTEM_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endSaveHl7SystemConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.SAVE_HL7_SYSTEM_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.SAVE_HL7_SYSTEM_CONFIGURATION);
  endRootSpan();
}

export function startGetHl7SystemConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_HL7_SYSTEM_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetHl7SystemConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_HL7_SYSTEM_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_HL7_SYSTEM_CONFIGURATION);
  endRootSpan();
}

export function startDeleteHl7SystemConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.DELETE_HL7_SYSTEM_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endDeleteHl7SystemConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.DELETE_HL7_SYSTEM_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.DELETE_HL7_SYSTEM_CONFIGURATION);
  endRootSpan();
}

export function startSaveHl7RuleConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.SAVE_HL7_RULE_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endSaveHl7RuleConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.SAVE_HL7_RULE_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.SAVE_HL7_RULE_CONFIGURATION);
  endRootSpan();
}

export function startGetHl7RuleConfigurationsSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_HL7_RULE_CONFIGURATIONS,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetHl7RuleConfigurationsSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_HL7_RULE_CONFIGURATIONS, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_HL7_RULE_CONFIGURATIONS);
  endRootSpan();
}

export function startGetHl7RuleConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_HL7_RULE_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetHl7RuleConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_HL7_RULE_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_HL7_RULE_CONFIGURATION);
  endRootSpan();
}

export function startDeleteHl7RuleConfigurationSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.DELETE_HL7_RULE_CONFIGURATION,
    SpanNames.ROOT_SPAN,
  );
}

export function endDeleteHl7RuleConfigurationSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.DELETE_HL7_RULE_CONFIGURATION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.DELETE_HL7_RULE_CONFIGURATION);
  endRootSpan();
}

export function startGetHl7SuccessMessageCountSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_HL7_SUCCESS_MESSAGE_COUNT,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetHl7SuccessMessageCountSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_HL7_SUCCESS_MESSAGE_COUNT, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_HL7_SUCCESS_MESSAGE_COUNT);
  endRootSpan();
}

export function startGetHl7ErroredMessageCountSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_HL7_ERRORED_MESSAGE_COUNT,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetHl7ErroredMessageCountSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_HL7_ERRORED_MESSAGE_COUNT, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_HL7_ERRORED_MESSAGE_COUNT);
  endRootSpan();
}

export function startGetHl7UnsentMessageCountSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_HL7_UNSENT_MESSAGE_COUNT,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetHl7UnsentMessageCountSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_HL7_UNSENT_MESSAGE_COUNT, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_HL7_UNSENT_MESSAGE_COUNT);
  endRootSpan();
}

export function startSearchHl7MessagesSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.SEARCH_HL7_MESSAGES,
    SpanNames.ROOT_SPAN,
  );
}

export function endSearchHl7MessagesSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.SEARCH_HL7_MESSAGES, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.SEARCH_HL7_MESSAGES);
  endRootSpan();
}

export function cancelSearchHl7MessagesSpan(): void {
  tracer.endSpan(SpanNames.SEARCH_HL7_MESSAGES);
  tracer.endSpan(SpanNames.ROOT_SPAN);
}

export function startGetHl7MessageSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(SpanNames.GET_HL7_MESSAGE, SpanNames.ROOT_SPAN);
}

export function endGetHl7MessageSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_HL7_MESSAGE, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_HL7_MESSAGE);
  endRootSpan();
}

export function startGetJwkSetUrlSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(SpanNames.GET_JWK_SET_URL, SpanNames.ROOT_SPAN);
}

export function endGetJwkSetUrlSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_JWK_SET_URL, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_JWK_SET_URL);
  endRootSpan();
}
