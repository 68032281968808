// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useSelector } from 'react-redux';
import {
  patientDeletionErrorSelector,
  patientDeletionOccurredSelector,
} from './selectors';

export const usePatientDetails = (): {
  deletionError: ReturnType<typeof patientDeletionErrorSelector>;
  patientDeletionOccurred: ReturnType<typeof patientDeletionOccurredSelector>;
} => {
  const deletionError = useSelector(patientDeletionErrorSelector);
  const patientDeletionOccurred = useSelector(patientDeletionOccurredSelector);
  return {
    deletionError,
    patientDeletionOccurred,
  };
};
