// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { FhirConfiguration } from '@imprivata-cloud/adminapi-client';
import type { RootState } from '../../../../../store/rootReducer';

export const isLoadingSelector = ({ fhirConfiguration }: RootState): boolean =>
  fhirConfiguration.loading;

export const configurationSelector = ({
  fhirConfiguration,
}: RootState): FhirConfiguration | null => fhirConfiguration.configuration;

export const errorSelector = ({
  fhirConfiguration,
}: RootState): string | null => fhirConfiguration.error;
