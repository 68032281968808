// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Skeleton } from 'antd';
import type { CSSProperties } from 'react';
import type { SkeletonProps } from 'antd';
import styles from './SkeletonLine.module.less';

const SkeletonLine: React.FC<
  SkeletonProps & {
    title?: boolean;
    lineClassName?: string;
    lineStyle?: CSSProperties;
  }
> = ({ title = false, lineClassName = '', lineStyle = {}, ...props }) => {
  return (
    <Skeleton
      paragraph={{
        rows: 0,
        className: styles.skeletonParagraph,
      }}
      title={{
        className: `${title ? styles.skeletonTitleLine : ''} ${
          lineClassName || ''
        }`,
        style: lineStyle,
      }}
      active={true}
      {...props}
    />
  );
};

export default SkeletonLine;
