// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { combineEpics } from 'redux-observable';
import { of } from 'rxjs';
import { map, switchMap, catchError, filter, tap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import type { Epic } from 'redux-observable';
import { ContextNames } from '../../../i18n';
import { getErrorMessageCode } from '../../../i18n/utils';
import { patientDeleteActions } from './actions';
import { deletePatient$ } from '../../../api/services/patientService';
import { endDeletePatientSpan, startDeletePatientSpan } from '../tracing';
import { showErrorBannerAction } from '../../../store/error-banner-state/actions';
import { errors } from '../../../store/error-banner-state/errors';

export const patientDeleteEpic: Epic = action$ =>
  action$.pipe(
    filter(isActionOf(patientDeleteActions.request)),
    tap(() => {
      startDeletePatientSpan();
    }),
    switchMap(({ payload }) => {
      return deletePatient$(payload).pipe(
        tap(() => {
          endDeletePatientSpan();
        }),
        map(payload => patientDeleteActions.success(payload)),
        catchError(error => {
          endDeletePatientSpan(error);
          return of(
            patientDeleteActions.failure({
              code: getErrorMessageCode(
                ContextNames.PATIENT_DELETION,
                'patient-delete-failed',
              ),
              message:
                'An error occurred while deleting for the patient. Please try again.',
            }),
            showErrorBannerAction.request(errors.PATIENT_DELETE_FAILED()),
          );
        }),
      );
    }),
  );

export const patientDetailsEpic = combineEpics(patientDeleteEpic);
