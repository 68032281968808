// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React from 'react';
import { Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import PageLayout from '../../components/page-layout/PageLayout';
import NavigationMenu from '../../components/navigation-menu/NavigationMenu';
import IntegrationsContextProvider from './components/integrations-context-provider/IntegrationsContextProvider';
import IntegrationsRoutes from './IntegrationsRoutes';

import classes from './Integrations.module.less';
import SetTitle from '../../utils/DynamicTitleHelper';
import pageLayoutClasses from '../../components/page-layout/PageLayout.module.less';
import { integrationsRoutes } from '../../routers/route-names';
import { LinkWithQuery } from '../../utils/routingHelpers';

const { Content, Sider } = Layout;

enum IntegrationMenuItemKeys {
  EmrConfiguration = 'emr',
  FhirConfiguration = 'fhir',
  Hl7Configuration = 'hl7',
}

const Integrations: React.FC = () => {
  const { t } = useTranslation();

  SetTitle(t('navigation.integrations'));

  const location = useLocation();
  const history = useHistory();

  const navigationMenuEffectFunction = () => {
    //This is for opening first tab of integrations menu by default
    if (location.pathname === '/integrations') {
      history.push(
        `/integrations/${IntegrationMenuItemKeys.FhirConfiguration}${location.search}`,
      );
    }
  };

  return (
    <PageLayout
      title={t('navigation.integrations')}
      className={classes.integrations}
      contentClassName={classes.content}
    >
      <IntegrationsContextProvider>
        <Layout className={classes.layout}>
          <Sider className={classes.sider}>
            <NavigationMenu
              componentEffectFunction={navigationMenuEffectFunction}
              defaultSelectedKeys={[IntegrationMenuItemKeys.FhirConfiguration]}
              items={[
                // {
                //   key: IntegrationMenuItemKeys.EmrConfiguration,
                //   label: (
                //     <LinkWithQuery
                //       data-testid="integrations-menu--emr-configuration"
                //       to={integrationsRoutes.EMR_CONFIGURATION}
                //       tabIndex={-1}
                //     >
                //       {t('navigation.emr-configuration')}
                //     </LinkWithQuery>
                //   ),
                // },
                {
                  key: IntegrationMenuItemKeys.FhirConfiguration,
                  label: (
                    <LinkWithQuery
                      data-testid="integrations-menu--fhir-configuration"
                      to={integrationsRoutes.FHIR_CONFIGURATION}
                      tabIndex={-1}
                    >
                      {t('navigation.fhir-configuration')}
                    </LinkWithQuery>
                  ),
                },
                {
                  key: IntegrationMenuItemKeys.Hl7Configuration,
                  label: (
                    <LinkWithQuery
                      data-testid="integrations-menu--hl7-configuration"
                      to={integrationsRoutes.HL7_CONFIGURATION}
                      tabIndex={-1}
                    >
                      {t('navigation.hl7-configuration')}
                    </LinkWithQuery>
                  ),
                },
              ]}
            />
          </Sider>
          <Content className={pageLayoutClasses.pageContent}>
            <IntegrationsRoutes />
          </Content>
        </Layout>
      </IntegrationsContextProvider>
    </PageLayout>
  );
};

export default Integrations;
