// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import type { ActivityType } from '@imprivata-cloud/adminapi-client';
import type { RootAction } from '../../../store/rootAction';
import type { MappedChartDatum } from '../../../api/types';
import { trendDataChartActions } from './actions';

export interface TrendDataChartState {
  loading: boolean;
  error: string | null;
  mappedChartData: MappedChartDatum[];
  chartDataTotals: {
    [key in keyof typeof ActivityType]?: number;
  };
  latestActivityDateTime: moment.Moment | null;
}

export interface DashboardState {
  trendData: TrendDataChartState;
}

export const initialState: DashboardState = {
  trendData: {
    loading: false,
    error: null,
    mappedChartData: [],
    chartDataTotals: {},
    latestActivityDateTime: null,
  },
};

export const trendDataReducer = combineReducers<TrendDataChartState>({
  loading: createReducer<boolean>(initialState.trendData.loading)
    .handleAction([trendDataChartActions.request], () => true)
    .handleAction(
      [
        trendDataChartActions.cancel,
        trendDataChartActions.success,
        trendDataChartActions.failure,
      ],
      () => false,
    ),
  mappedChartData: createReducer<MappedChartDatum[], RootAction>(
    initialState.trendData.mappedChartData,
  ).handleAction(trendDataChartActions.success, (_, { payload }) => {
    const mappedChartData: MappedChartDatum[] = [];
    payload.chartData?.forEach(cd => {
      cd.activityCounts?.forEach(ac => {
        mappedChartData.push({
          activityType: ac.activityType,
          label: cd.label,
          count: ac.count,
        });
      });
    });
    return mappedChartData;
  }),
  chartDataTotals: createReducer<
    {
      [key in keyof typeof ActivityType]?: number;
    },
    RootAction
  >(initialState.trendData.chartDataTotals).handleAction(
    trendDataChartActions.success,
    (_, { payload }) => payload.totals ?? {},
  ),
  latestActivityDateTime: createReducer<moment.Moment | null, RootAction>(
    initialState.trendData.latestActivityDateTime,
  ).handleAction(
    trendDataChartActions.success,
    (_, { payload }) => payload.latestActivityDateTime ?? null,
  ),
  error: createReducer<string | null, RootAction>(initialState.trendData.error)
    .handleAction(
      [trendDataChartActions.request, trendDataChartActions.success],
      () => null,
    )
    .handleAction(
      trendDataChartActions.failure,
      (_, { payload }) => payload.code || null,
    )
    .handleAction(trendDataChartActions.cancel, () => 'cancelled'),
});

export const dashboardReducer = combineReducers<DashboardState>({
  trendData: trendDataReducer,
});
