// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type {
  TenantType,
  GetTenantInfoResponse,
} from '@imprivata-cloud/adminapi-client';
import { map } from 'rxjs/operators';
import type { Observable } from 'rxjs';
import { promiseToObservable, adminApiClient } from '../client';

export function getTenantType$(): Observable<TenantType | null> {
  return promiseToObservable(adminApiClient.getTenantInfo()).pipe(
    map((res: GetTenantInfoResponse) => res.type ?? null),
  );
}
