// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Button, ButtonVariant } from '@imprivata-cloud/components';
import classes from './ConfirmActionModal.module.less';
import QuestionSvg from '../../assets/svg/question.svg';

interface Props {
  title: string;
  content: string;
  visible: boolean;
  cancelText: string;
  okText: string;
  closable?: boolean;
  onCancel: () => void;
  onSave: () => void;
  onClose?: () => void;
}

const ConfirmActionModal: React.FC<Props> = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible);

  useEffect(() => {
    setIsModalOpen(props.visible);
  }, [props.visible]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmClick = () => {
    closeModal();
    props.onSave();
  };

  const handleCancelClick = (e: React.MouseEvent<HTMLElement>) => {
    // don't close modal if click outside dialog
    if (e.currentTarget.className === 'ant-modal-wrap') {
      return;
    }
    // if clicked on the modal X icon just close the modal
    if (e.currentTarget.className === 'ant-modal-close') {
      closeModal();
      if (props.onClose) {
        props.onClose();
      }
    } else {
      // cancel button was clicked
      props.onCancel();
      closeModal();
    }
  };

  return (
    <>
      {isModalOpen && (
        <Modal
          className={classes.modal}
          cancelText={props.cancelText}
          okText={props.okText}
          open={props.visible}
          data-testid="confirm-action-modal"
          onCancel={handleCancelClick}
          onOk={handleConfirmClick}
          footer={null}
          closable={props.closable}
        >
          <div className={classes.modalContentWraper}>
            <div>
              <img src={QuestionSvg} alt="Question Mark" />
              <div className={classes.modalTitle}>{props.title}</div>
              <div className={classes.modalContent}>{props.content}</div>
            </div>
            <div className={classes.modalFooterButtons}>
              <Button
                data-testid="confirm-action-modal-confirm-button"
                variant={ButtonVariant.PRIMARY}
                onClick={handleConfirmClick}
                label={props.okText}
                autoFocus
              />
              <Button
                data-testid="confirm-action-modal-cancel-button"
                variant={ButtonVariant.SECONDARY}
                onClick={handleCancelClick}
                label={props.cancelText}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ConfirmActionModal;
