// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { concatMap, map } from 'rxjs/operators';
import type {
  GetPatientResponse,
  Patient,
  PatientSearchResponse,
} from '@imprivata-cloud/adminapi-client';
import { from, type Observable } from 'rxjs';
import type { PatientSearchRequestDecrypted } from '../../containers/patient-search/types';
import {
  adminApiClient,
  getCodingContext,
  promiseToObservable,
} from '../client';
import {
  IdentifierDecrypted,
  PatientDecrypted,
  PatientSearchResponseDecrypted,
} from '../types';

export function searchPatient$(
  request: PatientSearchRequestDecrypted,
): Observable<PatientSearchResponseDecrypted> {
  return promiseToObservable(
    getCodingContext().then(async codingContext =>
      adminApiClient.searchForPatient({
        identifiers: request.identifiers?.map(x =>
          codingContext.encryptString(x),
        ),
        phoneNumbers: request.phoneNumbers?.map(x =>
          codingContext.encryptString(x),
        ),
        emailAddresses: request.emailAddresses?.map(x =>
          codingContext.encryptString(x),
        ),
        givenNames: request.givenNames?.map(x =>
          codingContext.encryptString(x),
        ),
        surname: codingContext.encryptString(request.surname),
      }),
    ),
  ).pipe(
    concatMap((response: PatientSearchResponse) => {
      return from(decryptPatientSearchResponse(response));
    }),
  );
}

export function getPatient$(id: string): Observable<PatientDecrypted> {
  return promiseToObservable(adminApiClient.getPatient(id)).pipe(
    concatMap(async (response: GetPatientResponse) => {
      return decryptGetPatientResponse(response);
    }),
  );
}

export function deletePatient$(id: string): Observable<string> {
  return promiseToObservable(adminApiClient.deletePatient(id)).pipe(
    map(() => {
      return id;
    }),
  );
}

async function decryptPatientSearchResponse(
  response: PatientSearchResponse,
): Promise<PatientSearchResponseDecrypted> {
  return getCodingContext().then(
    codingContext =>
      new PatientSearchResponseDecrypted({
        patients: response.patients?.map<PatientDecrypted>(
          (patient: Patient) =>
            new PatientDecrypted({
              id: patient.id,
              givenNames: patient.givenNames?.map<string>(givenName =>
                codingContext.decryptToString({
                  data: givenName.data ?? '',
                }),
              ),
              surname: codingContext.decryptToString({
                data: patient.surname?.data ?? '',
              }),
              dateOfBirth: codingContext.decryptToString({
                data: patient?.dateOfBirth?.data ?? '',
              }),
              identifiers: patient.identifiers?.map<IdentifierDecrypted>(
                identifier =>
                  new IdentifierDecrypted({
                    system: identifier.system,
                    value: codingContext.decryptToString({
                      data: identifier.value?.data ?? '',
                    }),
                  }),
              ),
              phoneNumbers: patient.phoneNumbers?.map<string>(phoneNumber =>
                codingContext.decryptToString({
                  data: phoneNumber.data ?? '',
                }),
              ),
              emailAddresses: patient.emailAddresses?.map<string>(
                emailAddress =>
                  codingContext.decryptToString({
                    data: emailAddress.data ?? '',
                  }),
              ),
              createdTimestamp: patient.createdTimestamp,
              updatedTimestamp: patient.updatedTimestamp,
              createdAt: codingContext.decryptToString({
                data: patient.createdAt?.data ?? '',
              }),
              updatedAt: codingContext.decryptToString({
                data: patient.updatedAt?.data ?? '',
              }),
              createdBy: codingContext.decryptToString({
                data: patient.createdBy?.data ?? '',
              }),
              updatedBy: codingContext.decryptToString({
                data: patient.updatedBy?.data ?? '',
              }),
              photo: codingContext.decryptToString({
                data: patient.photo?.data ?? '',
              }),
              photoTakenDate: patient.photoTakenDate,
            }),
        ),
      }),
  );
}

async function decryptGetPatientResponse(
  response: GetPatientResponse,
): Promise<PatientDecrypted> {
  const patient = response.patient;
  return getCodingContext().then(
    codingContext =>
      new PatientDecrypted({
        id: patient?.id,
        givenNames: patient?.givenNames?.map<string>(givenName =>
          codingContext.decryptToString({
            data: givenName.data ?? '',
          }),
        ),
        surname: codingContext.decryptToString({
          data: patient?.surname?.data ?? '',
        }),
        dateOfBirth: codingContext.decryptToString({
          data: patient?.dateOfBirth?.data ?? '',
        }),
        identifiers: patient?.identifiers?.map<IdentifierDecrypted>(
          identifier =>
            new IdentifierDecrypted({
              system: identifier.system,
              value: codingContext.decryptToString({
                data: identifier.value?.data ?? '',
              }),
            }),
        ),
        phoneNumbers: patient?.phoneNumbers?.map<string>(phoneNumber =>
          codingContext.decryptToString({
            data: phoneNumber.data ?? '',
          }),
        ),
        emailAddresses: patient?.emailAddresses?.map<string>(emailAddress =>
          codingContext.decryptToString({
            data: emailAddress.data ?? '',
          }),
        ),
        createdTimestamp: patient?.createdTimestamp,
        updatedTimestamp: patient?.updatedTimestamp,
        createdAt: codingContext.decryptToString({
          data: patient?.createdAt?.data ?? '',
        }),
        updatedAt: codingContext.decryptToString({
          data: patient?.updatedAt?.data ?? '',
        }),
        createdBy: codingContext.decryptToString({
          data: patient?.createdBy?.data ?? '',
        }),
        updatedBy: codingContext.decryptToString({
          data: patient?.updatedBy?.data ?? '',
        }),
        photo: codingContext.decryptToString({
          data: patient?.photo?.data ?? '',
        }),
        photoTakenDate: patient?.photoTakenDate,
      }),
  );
}
