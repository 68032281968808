// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { SaveFhirConfigurationRequest } from '@imprivata-cloud/adminapi-client';
import {
  fhirConfigurationGetActions,
  fhirConfigurationSaveActions,
} from './actions';
import {
  isLoadingSelector,
  configurationSelector,
  errorSelector,
} from './selectors';

export const useGetFhirConfiguration = (): {
  configuration: ReturnType<typeof configurationSelector>;
  isLoading: ReturnType<typeof isLoadingSelector>;
  error: ReturnType<typeof errorSelector>;
} => {
  const dispatch = useDispatch();

  const loading = useSelector(isLoadingSelector);
  const configuration = useSelector(configurationSelector);
  const error = useSelector(errorSelector);

  const useMountEffect = () =>
    useEffect(() => {
      dispatch(fhirConfigurationGetActions.request());

      return () => {
        dispatch(fhirConfigurationGetActions.cancel());
      };
    }, []);
  useMountEffect();

  return { configuration, isLoading: loading, error };
};

export const useSaveFhirConfiguration = (
  request: SaveFhirConfigurationRequest,
): void => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fhirConfigurationSaveActions.request(request));
  }, [dispatch, request]);
};
