// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { Col, Row, Typography, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { Button } from '@imprivata-cloud/components';
import type { PatientDecrypted } from '../../../../api/types';
import classes from './PatientDemographics.module.less';
import SkeletonLine from '../../../../components/skeleton-line/SkeletonLine';

export interface PatientDemographicsProps {
  onDeleteButtonClick: () => void;
  patient: PatientDecrypted | null;
  isLoading: boolean;
}

const PatientDemographics: React.FC<PatientDemographicsProps> = (
  props: PatientDemographicsProps,
) => {
  const { t } = useTranslation();

  const isSurnameAvailable = !!props.patient?.surname?.length;
  const areGivenNamesAvailable = !!props.patient?.givenNames?.length;
  const fullName =
    props.patient && (isSurnameAvailable || areGivenNamesAvailable)
      ? `${
          isSurnameAvailable ? props.patient.surname : 'Last name not available'
        }, ${
          areGivenNamesAvailable
            ? props.patient?.givenNames?.join(' ')
            : 'First name not available'
        }`
      : 'Name not available';

  return (
    <>
      <Row data-testid="patient-demographics--patient-names">
        {props.isLoading ? (
          <SkeletonLine title lineClassName={classes.patientNameSkeleton} />
        ) : (
          <Typography.Title
            level={4}
            ellipsis={{
              tooltip: true,
            }}
            style={{ width: 600 }}
          >
            {fullName}
          </Typography.Title>
        )}
      </Row>
      <Row
        data-testid="patient-demographics--patient-dob"
        className={classes.patientDob}
      >
        {props.isLoading ? (
          <SkeletonLine lineClassName={classes.patientDobSkeleton} />
        ) : props.patient?.dateOfBirth?.length ? (
          props.patient.dateOfBirth
        ) : (
          'DOB not available'
        )}
      </Row>
      <Row>
        {props.isLoading ? (
          <SkeletonLine
            title
            lineClassName={classes.patientIdentifiersTitleSkeleton}
          />
        ) : (
          <Typography.Title level={4}>
            {t('patient-details.identifier')}
          </Typography.Title>
        )}
      </Row>
      {props.isLoading ? (
        <SkeletonLine lineClassName={classes.patientIdentifiersSkeleton} />
      ) : (
        props.patient?.identifiers?.map(identifier => (
          <Row
            data-testid="patient-demographics--patient-identifier"
            key={identifier.system ? identifier.system : ''}
          >
            <strong>MRN</strong>: {identifier.value}
          </Row>
        ))
      )}
      <Row className={classes.deleteButton}>
        <Col span={2}>
          {props.isLoading ? (
            <Skeleton.Button
              className={classes.deleteButtonSkeleton}
              active={true}
            />
          ) : (
            <Button
              data-testid="patient-demographics--delete-button"
              onClick={props.onDeleteButtonClick}
              label={t('patient-details.delete-button-text')}
            ></Button>
          )}
        </Col>
      </Row>
    </>
  );
};
export default PatientDemographics;
