// Copyright 2022, Imprivata, Inc.  All rights reserved.
/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from 'axios';
import { AdminUiApiClient } from '@imprivata-cloud/adminapi-client';
import {
  CodingContext,
  base64ToJson,
  createContextFromImprCodingCtxHeader,
  createContextFromImprCodingCtxHeaderV2,
  getRSAPublicKey,
} from '@imprivata-cloud/data-privacy-js';
import { Observable, from } from 'rxjs';
import type { AxiosInstance } from 'axios';
import { Headers, invalidSessionError } from './constants';
import getConfig from '../appConfigUtils';
const { PA_API_URL, METADATA_URL } = getConfig();

const securityHeaders = {
  [Headers.StrictTransportSecurity]: 'max-age=86400;includeSubDomains',
  [Headers.XSSProtection]: '1;mode=block',
  [Headers.XFrameOptions]: 'DENY',
  [Headers.XContentTypeOptions]: 'nosniff',
};

let _codingContext: CodingContext;

export const client: AxiosInstance = axios.create({
  baseURL: PA_API_URL,
  withCredentials: true,
  headers: {
    ...securityHeaders,
  },
  transformResponse: data => data,
});

export const noCredentialsClient: AxiosInstance = axios.create({
  headers: {
    ...securityHeaders,
  },
  transformResponse: data => data,
});

export const adminApiClient = new AdminUiApiClient(PA_API_URL, client);

export const noCredentialsAdminApiClient = new AdminUiApiClient(
  undefined,
  noCredentialsClient,
);

// Need this method so that TestScheduler can handle the NSwag client
export function promiseToObservable(promise: Promise<any>): Observable<any> {
  return new Observable(subscriber => {
    let completed = false;
    const cancelSource = axios.CancelToken.source();
    const reqSubscription = from(promise).subscribe({
      next: data => {
        completed = true;
        subscriber.next(data);
      },
      error: error => {
        completed = true;
        if (error.message === invalidSessionError) {
          subscriber.complete();
        } else {
          subscriber.error(error);
        }
      },
      complete: () => {
        completed = true;
        subscriber.complete();
      },
    });

    return () => {
      reqSubscription.unsubscribe();
      if (!completed) {
        cancelSource.cancel('Unsubscribed from request observable');
      }
    };
  });
}

async function createCodingContext(): Promise<CodingContext> {
  return CodingContext.createContextV1();
  // try {
  //   const publicKey = await getRSAPublicKey(METADATA_URL);
  //   return await CodingContext.createContextV2(
  //     publicKey.key,
  //     publicKey.version,
  //   );
  // } catch (error) {
  //   return CodingContext.createContextV1();
  // }
}

export async function getCodingContext(): Promise<CodingContext> {
  if (!_codingContext) {
    _codingContext = await createCodingContext();
  }
  return _codingContext;
}

export function setCodingContext(context: CodingContext) {
  _codingContext = context;
}

export async function setCodingContextFromHeader(ctxHeader: string) {
  const contextJson = base64ToJson(ctxHeader);
  const context =
    contextJson.version === 1
      ? createContextFromImprCodingCtxHeader(ctxHeader)
      : await createContextFromImprCodingCtxHeaderV2(ctxHeader);
  setCodingContext(context);
}
