// Copyright 2022, Imprivata, Inc.  All rights reserved.

export const contextType = 'generic-app-login';
export const resourceType = 'ps-admin-web-ui';
export const invalidSessionError = 'invalid-session';
export enum Headers {
  ImprAuthnResource = 'Impr-Authn-Resource',
  ImprTenantId = 'Impr-Tenant-Id',
  ImprSessionId = 'Impr-Session-Id',
  ImprCodingCtx = 'Impr-Coding-Ctx',
  XContentTypeOptions = 'X-Content-Type-Options',
  XSSProtection = 'X-XSS-Protection',
  XFrameOptions = 'X-Frame-Options',
  StrictTransportSecurity = 'Strict-Transport-Security',
}
