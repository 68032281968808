// Copyright 2022, Imprivata, Inc.  All rights reserved.

import React, { createContext, useContext, useState } from 'react';
import type { PropsWithChildren } from 'react';

export interface IntegrationsContextType {
  link: string;
  setLink: React.Dispatch<React.SetStateAction<string>>;
}

export const IntegrationsContext = createContext<
  Partial<IntegrationsContextType>
>({
  link: '',
});

export const useIntegrationsContext = (): Partial<IntegrationsContextType> => {
  const context = useContext(IntegrationsContext);

  return context;
};

const IntegrationsContextProvider = ({
  children,
}: PropsWithChildren<unknown>) => {
  const [link, setLink] = useState('');
  return (
    <IntegrationsContext.Provider value={{ link, setLink }}>
      {children}
    </IntegrationsContext.Provider>
  );
};

export default IntegrationsContextProvider;
