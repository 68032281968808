// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable  @typescript-eslint/no-explicit-any */
import type { RawSpanAttributes } from '@imprivata-cloud/common';
import {
  tracer,
  SpanNames,
  startRootSpan,
  recordException,
  endRootSpan,
} from '../../tracing';

export function startGetFactorOptionsSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.GET_FACTOR_OPTIONS,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetFactorOptionsSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_FACTOR_OPTIONS, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_FACTOR_OPTIONS);
  endRootSpan();
}

export function startGetOrganizationPreferencesSpan(): void {
  tracer.startSubspanFromParent(
    SpanNames.GET_ORGANIZATION_PREFERENCES,
    SpanNames.ROOT_SPAN,
  );
}

export function endGetOrganizationPreferencesSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_ORGANIZATION_PREFERENCES, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_ORGANIZATION_PREFERENCES);
  endRootSpan();
}

export function startCreateAuthnSessionSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.CREATE_AUTHN_SESSION,
    SpanNames.ROOT_SPAN,
  );
}

export function endCreateAuthnSessionSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.CREATE_AUTHN_SESSION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.CREATE_AUTHN_SESSION);
}

export function startLoginSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(SpanNames.LOGIN, SpanNames.ROOT_SPAN);
}

export function endLoginSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.LOGIN, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.LOGIN);
  endRootSpan();
}

export function startAutoLoginSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(SpanNames.AUTO_LOGIN, SpanNames.ROOT_SPAN);
}

export function endAutoLoginSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.AUTO_LOGIN, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.AUTO_LOGIN);
  endRootSpan();
}

export function startLogoutSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(SpanNames.LOGOUT, SpanNames.ROOT_SPAN);
}

export function endLogoutSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.LOGOUT, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.LOGOUT);
  endRootSpan();
}

export function startGetTenantTypeSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(SpanNames.GET_TENANT_TYPE, SpanNames.ROOT_SPAN);
}

export function endGetTenantTypeSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.GET_TENANT_TYPE, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.GET_TENANT_TYPE);
  endRootSpan();
}

export function endAuthenticationTracingSpan(
  attributes?: RawSpanAttributes,
): void {
  tracer.endSpan(SpanNames.AUTHENTICATION, attributes);
}

export function startCreateUserSessionTracingSpan(): void {
  startRootSpan();
  tracer.startSubspanFromParent(
    SpanNames.CREATE_USER_SESSION,
    SpanNames.ROOT_SPAN,
  );
}

export function endCreateUserSessionTracingSpan(error: any = null): void {
  if (error) {
    recordException(SpanNames.CREATE_USER_SESSION, error);
    recordException(SpanNames.ROOT_SPAN, error);
  }

  tracer.endSpan(SpanNames.CREATE_USER_SESSION);
}
