// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { createReducer } from 'typesafe-actions';
import type { BannerProps } from '@imprivata-cloud/components';
import { combineReducers } from 'redux';
import type { RootAction } from '../rootAction';
import { deleteErrorBannerItemAction, showErrorBannerAction } from './actions';

export interface ErrorBannerState {
  error: BannerProps | null;
  errors: BannerProps[];
}

export const initialState: ErrorBannerState = {
  error: null,
  errors: [],
};

export const errorBannerReducer = combineReducers<ErrorBannerState>({
  error: createReducer<BannerProps | null, RootAction>(
    initialState.error,
  ).handleAction(showErrorBannerAction.success, (_, { payload }) => payload),
  errors: createReducer<BannerProps[], RootAction>(initialState.errors)
    .handleAction(showErrorBannerAction.success, (state, { payload }) => {
      return [...state, payload];
    })
    .handleAction(deleteErrorBannerItemAction, (state, { payload }) => {
      const index = state.findIndex(
        (error: BannerProps) =>
          error?.description === payload?.description &&
          error.message === payload.message,
      );
      if (index === -1) {
        return state;
      }
      return [...state.slice(0, index), ...state.slice(index + 1)];
    }),
});
