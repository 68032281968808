// Copyright 2022, Imprivata, Inc.  All rights reserved.

/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  SaveHl7RuleConfigurationRequest,
  SaveHl7SystemConfigurationRequest,
} from '@imprivata-cloud/adminapi-client';
import type { Dispatch } from 'react';
import {
  hl7MessageActions,
  hl7RuleConfigurationDeleteActions,
  hl7RuleConfigurationGetActions,
  hl7RuleConfigurationSaveActions,
  hl7SystemConfigurationDeleteActions,
  hl7SystemConfigurationGetActions,
  hl7SystemConfigurationSaveActions,
} from './actions';

export const saveHl7SystemConfiguration = (
  request: SaveHl7SystemConfigurationRequest,
  dispatch: Dispatch<any>,
): void => {
  dispatch(hl7SystemConfigurationSaveActions.request(request));
};

export const getHl7SystemConfiguration = (dispatch: Dispatch<any>): void => {
  dispatch(hl7SystemConfigurationGetActions.request());
};

export const deleteHl7SystemConfiguration = (dispatch: Dispatch<any>): void => {
  dispatch(hl7SystemConfigurationDeleteActions.request());
};

export const saveHl7RuleConfiguration = (
  request: SaveHl7RuleConfigurationRequest,
  dispatch: Dispatch<any>,
): void => {
  dispatch(hl7RuleConfigurationSaveActions.request(request));
};

export const getHl7RuleConfigurations = (dispatch: Dispatch<any>): void => {
  dispatch(hl7RuleConfigurationGetActions.request());
};

export const deleteHl7RuleConfiguration = (
  ruleId: string,
  dispatch: Dispatch<any>,
): void => {
  dispatch(hl7RuleConfigurationDeleteActions.request(ruleId));
};

export const getHl7Message = (id: string, dispatch: Dispatch<any>): void => {
  dispatch(hl7MessageActions.request(id));
};
