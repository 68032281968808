// Copyright 2022, Imprivata, Inc.  All rights reserved.

import type { Observable } from 'rxjs';
import { promiseToObservable, adminApiClient } from '../client';

export function startClientUserSession$(): Observable<void> {
  return promiseToObservable(adminApiClient.startClientUserSession());
}

export function logout$(): Observable<void> {
  return promiseToObservable(adminApiClient.logout());
}
