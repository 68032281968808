// Copyright 2022, Imprivata, Inc.  All rights reserved.
import { Card, Col, Row, Typography } from 'antd';
import type { GetHl7MessageResponse } from '@imprivata-cloud/adminapi-client';
import moment from 'moment-timezone';
import classes from './Hl7MessageDetails.module.less';
import { Hl7MessageType } from '../../Hl7ConfigurationContainer';

const { Text } = Typography;

interface IHl7MessageDetails {
  message?: GetHl7MessageResponse | null;
  show: boolean;
  className?: string;
}

const Hl7MessageDetails: React.FC<IHl7MessageDetails> = ({
  message,
  show,
  className,
}) => {
  return (
    <Card
      size="small"
      bordered={false}
      className={`${classes.messageCard} ${className} ${
        show ? classes.show : classes.hide
      }`}
      loading={!message}
    >
      <Row gutter={16}>
        <Col flex="auto" lg={8} className={classes.messageDetailsCol}>
          <div>
            <Text strong>Receiving URL</Text>
            <div className={classes.destinationUrl}>
              {message?.destinationUrl ?? 'N/A'}
            </div>
          </div>
          <div className={classes.additionalInformationContainer}>
            <Text strong>Additional Information</Text>
            <div className={classes.sentTimestamp}>
              Sent:{' '}
              {message?.messageStatus === Hl7MessageType.Success &&
              message?.sentTimestamp
                ? `${moment(message?.sentTimestamp).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )}`
                : 'N/A'}
            </div>
            <div>
              Error:{' '}
              {message && message.errorMessage ? message.errorMessage : 'N/A'}
            </div>
          </div>
        </Col>
        <Col lg={8} className={classes.messageDetailsCol}>
          <div className={classes.messageContainer}>
            <Text strong>Message</Text>
            <textarea
              className={classes.messageText}
              rows={6}
              defaultValue={message?.messageText}
              readOnly
            ></textarea>
          </div>
        </Col>
        <Col lg={8} className={classes.messageDetailsCol}>
          <div className={classes.messageContainer}>
            <Text strong>ACK</Text>
            <textarea
              className={classes.messageText}
              rows={6}
              defaultValue={message?.ackText}
              readOnly
            ></textarea>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default Hl7MessageDetails;
